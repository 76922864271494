<template>
  <div class="content-wrapper">
    <ContentHeader header="Utility" title="User Management" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <v-btn
                  class="btn bg-navy mb-3 mb-md-0"
                  @click="handleModalForm(true)"
                >
                  <i class="fa fa-plus"></i>
                  Tambah
                </v-btn>
                <div class="d-flex justify-content-end">
                  <v-text-field
                    class="input-search"
                    label="Cari"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    dense
                    v-model="optionsTable.search"
                  />
                </div>
                <v-data-table
                  :headers="headers"
                  :items="reports"
                  :loading="isLoading"
                  :options.sync="optionsTable"
                  :search="optionsTable.search"
                >
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip v-if="item.is_active" small color="success">
                      Active
                    </v-chip>
                    <v-chip v-else small color="red" class="text-white">
                      Inactive
                    </v-chip>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      icon
                      color="white"
                      class="bg-warning mr-2"
                      @click="handleUpdate(item.id)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="white"
                      class="bg-danger"
                      @click="handleDelete(item.id)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalForm" persistent max-width="700">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "UtilityUserManagementPage",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
  },
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Business Unit", value: "business_unit.code" },
        { text: "Code", value: "user_code" },
        { text: "Name", value: "name" },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false, align: "right" },
      ],
      modalForm: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.userManagement.isLoading;
    },
    reports() {
      return this.$store.state.userManagement.reports;
    },
    optionsTable: {
      get() {
        return this.$store.state.userManagement.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_USER_MANAGEMENT", value);
      },
    },
  },
  methods: {
    handleModalForm(val) {
      if (val) this.$store.dispatch("FetchBeforeFormUserManagement");
      this.modalForm = val;
    },
    handleUpdate(id) {
      this.$store.commit("SET_IS_UPDATE_USER_MANAGEMENT", id);
      this.$store.dispatch("SetFormUpdateUserManagement", id);
      this.handleModalForm(true);
    },
    handleDelete(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DeleteUserManagement", id);
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("GetAllUserManagement");
  },
};
</script>
