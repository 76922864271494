import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const rateCity = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    reportsTotal: 0,
    list_city: [],
    form: {
      code: "",
      from_city_id: "",
      province: "",
      city: "",
      district: "",
      code_reg: "",
      price: "",
      day_min_reg: "",
      day_max_reg: "",
      post_code: "",
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_RATE_CITY(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_RATE_CITY(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_RATE_CITY(state, payload) {
      state.reports = payload;
    },
    SET_REPORTS_TOTAL_RATE_CITY(state, payload) {
      state.reportsTotal = payload;
    },
    SET_LIST_CITY_RATE_CITY(state, payload) {
      state.list_city = payload;
    },
    SET_FORM_RATE_CITY(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_RATE_CITY(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllRateCity(context, { limit, page, sortBy, keyword } = {}) {
      context.commit("SET_IS_LOADING_RATE_CITY", true);
      try {
        const result = await axios.get(`${apiUrl}/rate-city`, {
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          params: {
            limit,
            page,
            sortBy,
            keyword,
          },
        })

        context.commit("SET_REPORTS_RATE_CITY", result.data.data.data);
        context.commit("SET_REPORTS_TOTAL_RATE_CITY", result.data.data.total);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RATE_CITY", false);
      }
    },
    async FetchBeforeFormRateCity(context) {
      context.commit("SET_IS_LOADING_RATE_CITY", true);
      try {
        const resultCity = await axios({
          url: `${apiUrl}/city`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_CITY_RATE_CITY", resultCity.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RATE_CITY", false);
      }
    },
    async CreateRateCity(context) {
      context.commit("SET_IS_LOADING_RATE_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/rate-city`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetAllRateCity");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_RATE_CITY", false);
      }
    },
    async SetFormUpdateRateCity(context, id) {
      context.commit("SET_IS_LOADING_RATE_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/rate-city/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          code: result.data.data.code,
          from_city_id: result.data.data.from_city_id,
          province: result.data.data.province,
          city: result.data.data.city,
          district: result.data.data.district,
          code_reg: result.data.data.code_reg,
          price: result.data.data.price,
          day_min_reg: result.data.data.day_min_reg,
          day_max_reg: result.data.data.day_max_reg,
          post_code: result.data.data.post_code,
          sub_district: result.data.data.sub_district,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RATE_CITY", false);
      }
    },
    async UpdateRateCity(context, id) {
      context.commit("SET_IS_LOADING_RATE_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/rate-city/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetAllRateCity");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_RATE_CITY", false);
      }
    },
    async DeleteRateCity(context, id) {
      context.commit("SET_IS_LOADING_RATE_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/rate-city/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetAllRateCity");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_RATE_CITY", false);
      }
    },
  },
};

export default rateCity;
