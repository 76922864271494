<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2 mt-3">
          <div class="col-sm-6">
            <h1>Check Price</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left -->
          <!-- <div class="col-12 mt-3 mt-lg-0">
            <div class="card py-4 px-3 h-100">
              <div class="card-body text-center">
                <h4 class="fw-bold">Lacak Kiriman Paket</h4>
                <p class="text-muted">Masukkan nomor resi dan tekan 'Lacak'</p>

                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn bg-navy" type="button">Lacak</button>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- right -->
          <div class="col-12 mt-3">
            <v-card class="shadow-none border py-4 px-3 h-100">
              <div class="card-body">
                <v-form
                  ref="initialReport"
                  style="max-width: 1000px"
                  @submit.prevent="handleSubmit"
                >
                  <h4 class="fw-bold">Check Shipping Rates</h4>
                  <div class="row align-items-center mt-3">
                    <div class="col-12 col-md-6">
                      <label class="form-label fst-italic small">From</label>
                      <v-autocomplete
                        outlined
                        dense
                        placeholder="Select from city"
                        v-model="fromCityId"
                        :items="list_from_city"
                        item-text="tbm_city.name"
                        item-value="from_city_id"
                        @change="OnChangeFromCity"
                        :rules="[
                          (value) => {
                            return genericRequiredRule(value, 'From City');
                          },
                        ]"
                      />
                    </div>
                    <div class="col-12 col-md-6">
                      <label class="form-label fst-italic small"
                        >Destination</label
                      >
                      <city-destination-select v-model="toCityId" :fromCity="fromCityId"/>
                    </div>
                    <div class="col-12">
                      <label class="form-label fst-italic small">
                        Weight
                      </label>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0"
                        type="number"
                        suffix="Kg"
                        v-model="weight"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label fst-italic small">
                        Length
                      </label>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0"
                        type="number"
                        suffix="cm"
                        v-model="length"
                        @input="calculateVolume()"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label fst-italic small"> Width </label>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0"
                        type="number"
                        suffix="cm"
                        v-model="width"
                        @input="calculateVolume()"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label fst-italic small">
                        Height
                      </label>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0"
                        type="number"
                        suffix="cm"
                        v-model="height"
                        @input="calculateVolume()"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label fst-italic small">
                        Volume
                      </label>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0"
                        type="number"
                        suffix="cm³"
                        v-model="volume"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-3 mt-lg-0">
                    <v-btn class="btn bg-navy px-15" type="submit">Cek</v-btn>
                  </div>
                </v-form>
              </div>
            </v-card>
          </div>
        </div>

        <div class="row" v-if="result">
          <div class="col">
            <v-card class="shadow-none border mt-5 mb-3">
              <div class="card-body">
                <h6 class="mb-5 fw-medium">Result</h6>

                <table class="table">
                  <tr>
                    <td>From City</td>
                    <td>:</td>
                    <td class="text-capitalize">{{ result.fromCity }}</td>
                  </tr>
                  <tr>
                    <td>Destination</td>
                    <td>:</td>
                    <td>{{ result.toCity }}</td>
                  </tr>
                  <tr>
                    <td>Shipping fee for {{ result.weight }} kg</td>
                    <td>:</td>
                    <td>
                      <span class="font-weight-bold">{{ result.price }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Days Estimation</td>
                    <td>:</td>
                    <td>
                      <span class="font-weight-bold">{{ result.day_min }} - {{ result.day_max }} D </span>
                    </td>
                  </tr>
                </table>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import Swal from "sweetalert2";

export default {
  name: "CheckPricePage",
  mixins: [ValidationRules],
  data() {
    return {
      length: "",
      width: "",
      height: "",
      destinationSearch: "",
    };
  },
  computed: {
    list_from_city() {
      return this.$store.state.checkPrice.list_from_city;
    },
    result() {
      return this.$store.state.checkPrice.result;
    },
    fromCityId: {
      get() {
        return this.$store.state.checkPrice.form_check_price.fromCityId;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHECK_PRICE", {
          key: "fromCityId",
          value,
        });
      },
    },
    toCityId: {
      get() {
        return this.$store.state.checkPrice.form_check_price.toCityId;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHECK_PRICE", {
          key: "toCityId",
          value,
        });
      },
    },
    weight: {
      get() {
        return this.$store.state.checkPrice.form_check_price.weight;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHECK_PRICE", {
          key: "weight",
          value,
        });
      },
    },
    volume: {
      get() {
        return this.$store.state.checkPrice.form_check_price.volume;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHECK_PRICE", {
          key: "volume",
          value,
        });
      },
    },
  },
  methods: {
    OnChangeFromCity(id, keyword) {
      this.$store.dispatch("GetListToCityCheckPrice", {fromCityId: id, keyword});
    },
    handleSubmit() {
      if (!this.$refs.initialReport.validate()) {
        return;
      }

      if (!this.weight && !this.volume) {
        Swal.fire({
          title: "Opps...",
          text: "At least input weight or volume",
          icon: "warning",
        });
        return;
      }

      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("CheckPrice");
      }
    },
    calculateVolume() {
      if (!this.length) {
        return;
      }
      if (!this.height) {
        return;
      }
      if (!this.width) {
        return;
      }
      this.volume = (this.length * this.height * this.width) / 6000;
    },
  },
  mounted() {
    this.$store.dispatch("GetListFromCity");
  },
  beforeDestroy() {
    this.$refs.initialReport.reset();
    this.$store.commit("SET_RESULT_CHECK_PRICE", "");
  },
};
</script>
