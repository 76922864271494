<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment Out" title="Receipt List" />

    <section class="content">
      <div class="container-fluid">
        <div class="d-md-flex flex-wrap mb-3">
          <v-btn
            class="btn bg-navy mr-md-3 mb-3 mb-md-0"
            @click="handleModalForm(true)"
            v-if="isAgent"
          >
            Add Receipt
          </v-btn>
          <HandleImportReceipt v-if="isAgent" />
          <FormRequestPickup v-if="isAgent" />
        </div>
        <div class="d-md-flex flex-wrap mb-3 justify-content-end">
          <HandleImportTracking v-if="isHub" />
          <HandleExportTransactionPerDay />
          <HandleExportDataToExcel />
          <v-btn
            class="btn bg-navy ml-md-3 mb-3 mb-md-0"
            @click="handleModalPrintReceipt(true)"
          >
            Print Receipt List
          </v-btn>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <div class="row mb-5 align-items-center">
                  <div v-if="!isAgent" class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-autocomplete
                      label="Business Unit"
                      :items="business_unit_list"
                      v-model="business_unit_active"
                      @change="setValueTab"
                      outlined
                      dense
                      hide-details="true"
                    />
                  </div>
                  <div class="col-md-7 col-lg-6 mb-3 mb-sm-0">
                    <DateRangePicker />
                  </div>
                </div>

                <br />

                <ul class="pl-0 nav nav-tabs">
                  <li class="nav-item" v-for="(item, i) in tab_list" :key="i">
                    <a
                      :class="`nav-link ${
                        item.title === tab_active && 'active fw-semibold'
                      }`"
                      @click="() => (tab_active = item.title)"
                    >
                      {{ item.title }}
                      <span :class="`badge ${item.color} badge-pill`">
                        {{ item.value }}
                      </span>
                    </a>
                  </li>
                </ul>

                <div class="card shadow-none border border-top-0 rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <HandlePrintSelected />
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="optionsTable.search"
                      />
                    </div>
                    <v-data-table
                      :headers="headers"
                      :items="setReportsByTab(reports)"
                      :loading="isLoading"
                      :options.sync="optionsTable"
                      :search="optionsTable.search"
                      show-select
                      v-model="form_select_print"
                    >
                      <template v-slot:[`item.created_at`]="{ item }">
                        {{ moment(item.created_at).format("DD-MM-YYYY hh:mm") }}
                      </template>
                      <template v-slot:[`item.total_amount`]="{ item }">
                        {{ format3Digit(item.total_amount) }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="btn btn-outline-primary py-4"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="fw-light mr-1">Action</span>
                              <i class="fa-solid fa-chevron-down small"></i>
                            </v-btn>
                          </template>
                          <v-list min-width="150">
                            <v-list-item @click="handleDetail(item.id)">
                              <v-list-item-title class="text-primary">
                                <i class="fa-regular fa-eye small mr-2"></i>
                                <span class="small">Detail</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleEdit(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-edit small mr-2"></i>
                                <span class="small">Edit</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleBarcodeReceipt(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-print small mr-2"></i>
                                <span class="small">Print Barcode</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleModalCN32(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-print small mr-2"></i>
                                <span class="small">Print CN32</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleCancel(item)"
                              v-if="['ENTRY', 'POSTED'].includes(item.status) && (isAdmin || isHub)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-edit small mr-2"></i>
                                <span class="small">Cancel</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalForm" persistent max-width="800">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
    <v-dialog v-model="modalDetail" persistent max-width="1200">
      <Detail @handleModalDetail="handleModalDetail" />
    </v-dialog>
    <v-dialog v-if="modalCN32" v-model="modalCN32" persistent max-width="1200">
      <DocCN32 @handleModalCN32="handleModalCN32" />
    </v-dialog>
    <v-dialog
      v-if="barcodeReceipt"
      v-model="barcodeReceipt"
      persistent
      max-width="1200"
    >
      <BarcodeReceipt @handleBarcodeReceipt="handleBarcodeReceipt" />
    </v-dialog>
    <v-dialog
      v-if="modalPrintReceipt"
      v-model="modalPrintReceipt"
      persistent
      max-width="800"
    >
      <PrintReceipt @handleModalPrintReceipt="handleModalPrintReceipt" />
    </v-dialog>
  </div>
</template>

<script>
import format3Digit from "@/utils/format-3digit";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "ShipmentOutReceiptList",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
    HandleImportReceipt: () => import("./handle-import-receipt.vue"),
    Detail: () => import("./detail.vue"),
    FormRequestPickup: () => import("./request-pick-up.vue"),
    DocCN32: () => import("@/components/document/cn32/index.vue"),
    BarcodeReceipt: () =>
      import("@/components/document/barcode-receipt/index.vue"),
    PrintReceipt: () => import("./print-recipt.vue"),
    DateRangePicker: () =>
      import("@/components/molecules/date-range-picker.vue"),
    HandlePrintSelected: () => import("./handle-print-selected.vue"),
    HandleImportTracking: () => import("./handle-import-tracking.vue"),
    HandleExportTransactionPerDay: () =>
      import("./handle-export-transaction-per-day.vue"),
    HandleExportDataToExcel: () => import("./handle-export-data-to-excel.vue"),
  },
  data() {
    return {
      moment,
      format3Digit,
      business_unit_list: ["All"],
      business_unit_active: "All",
      tab_active: "Entry",
      tab_list: [
        { title: "Entry", value: 0, color: "badge-info" },
        { title: "Posted", value: 0, color: "badge-success" },
        { title: "In Progress", value: 0, color: "badge-light" },
        { title: "Delivered", value: 0, color: "badge-warning" },
        { title: "Canceled", value: 0, color: "badge-danger" },
        { title: "Failed", value: 0, color: "badge-danger" },
        { title: "All", value: 0, color: "badge-dark" },
      ],
      headers: [
        { text: "Code", value: "code" },
        { text: "Date", value: "created_at" },
        { text: "Sender City", value: "rate_city.tbm_city.name" },
        { text: "Sender Name", value: "sender_name" },
        { text: "Receiver City", value: "rate_city.city" },
        { text: "Receiver Name", value: "receiver_name" },
        { text: "Bruto", value: "weight" },
        { text: "Total Amount", value: "total_amount" },
        { text: "Tracking", value: "tracking_last" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      modalForm: false,
      modalDetail: false,
      modalFormRequestPickup: false,
      modalPrintReceipt: false,
      modalCN32: false,
      barcodeReceipt: false,
    };
  },
  computed: {
    isAgent() {
      return this.$store.state.app.user.business_unit.type === "Agent"
        ? true
        : false;
    },
    isHub() {
      return this.$store.state.app.user.business_unit.type === "Hub"
        ? true
        : false;
    },
    isAdmin(){
      return this.$store.state.app.user.business_unit.type === "Administrator"
        ? true
        : false;
    },
    isLoading() {
      return this.$store.state.receipt.isLoading;
    },
    reports() {
      return this.$store.state.receipt.reports;
    },
    optionsTable: {
      get() {
        return this.$store.state.receipt.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_RECEIPT", value);
      },
    },
    form_select_print: {
      get() {
        return this.$store.state.receipt.form_select_print;
      },
      set(value) {
        this.$store.commit("SET_FORM_SELECT_PRINT_RECEIPT", value);
      },
    },
    start_date() {
      return this.$store.state.app.filter_range.start_date;
    },
    end_date() {
      return this.$store.state.app.filter_range.end_date;
    },
  },
  watch: {
    reports() {
      this.setValueTab();
    },
    start_date() {
      this.handleMounted();
    },
    end_date() {
      this.handleMounted();
    },
  },
  methods: {
    handleModalForm(val) {
      if (val) this.$store.dispatch("FetchBeforeFormReceipt");
      this.modalForm = val;
    },
    handleEdit(id) {
      this.$store.commit("SET_IS_UPDATE_RECEIPT", id);
      this.$store.dispatch("SetFormUpdateReceipt", id);
      this.handleModalForm(true);
    },
    handleModalDetail(val) {
      this.modalDetail = val;
    },
    handleDetail(id) {
      this.$store.dispatch("FetchReceiptById", id);
      this.handleModalDetail(true);
    },
    handleModalCN32(val, id) {
      if (val) this.$store.dispatch("FetchDocsBarcodeCN32", id);
      this.modalCN32 = val;
    },
    handleBarcodeReceipt(val, id) {
      if (val) this.$store.dispatch("FetchDocsBarcodeReceiptById", id);
      this.barcodeReceipt = val;
    },
    handleModalPrintReceipt(val) {
      if (val) this.$store.dispatch("FetchBeforePrintReceipt");
      this.modalPrintReceipt = val;
    },
    setReportsByTab() {
      let result = [];
      if (this.tab_active === "All") {
        result = this.reports;
      } else {
        result = this.reports.filter(
          (x) => x.status.toLowerCase() === this.tab_active.toLowerCase()
        );
      }

      return result.filter(
        (x) =>
          x.business_unit.code === this.business_unit_active ||
          this.business_unit_active === "All"
      );
    },
    setValueTab() {
      this.tab_list.forEach((item) => {
        item.value = this.reports.filter(
          // set lower case to make it case insensitive
          (x) => x.status.toLowerCase() === item.title.toLowerCase()
        ).length;
      });

      this.tab_list[6].value = this.reports.length;

      for (const iterator of this.reports) {
        if (!this.business_unit_list.includes(iterator.business_unit.code)) {
          this.business_unit_list.push(iterator.business_unit.code);
        }
      }
    },
    async handleMounted() {
      await this.$store.dispatch("FetchReceipt");

      this.setValueTab();
    },
    async handleCancel(receipt) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `This receipt ${receipt.code} will be moved to canceled tab`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes, Cancel it!",
      })

      if(!result.isConfirmed) {
        return;
      }

      const submitResult = await this.$store.dispatch("CancelReceipt", receipt.id);

      if(submitResult) {
        await Swal.fire({
          icon: "success",
          title: "Success",
          text: "Receipt canceled",
        });
      }

    }
  },
  mounted() {
    this.handleMounted();

    if (this.$route.query.status) {
      this.tab_active = this.$route.query.status;
    }
  },
};
</script>
