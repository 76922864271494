import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;

const inHubOrigin = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    list_inHubOrigin: [],
    list_receipt_receiveAtWarehouse: [],
  },
  mutations: {
    SET_OPTIONS_TABLE_IN_HUB_ORIGIN(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_IN_HUB_ORIGIN(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_IN_HUB_ORIGIN(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_IN_HUB_ORIGIN(state, payload) {
      state.report = payload;
    },
    SET_LIST_IN_HUB_ORIGIN(state, payload) {
      state.list_inHubOrigin = payload;
    },
    SET_LIST_RECEIPT_RECEIVE_AT_WAREHOUSE(state, payload) {
      state.list_receipt_receiveAtWarehouse = payload;
    },
  },
  actions: {
    async GetAllinHubOrigin(context) {
      context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", true);
      try {
        const result = await axios({
          url: `${apiUrl}/in-hub-origin?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORTS_IN_HUB_ORIGIN", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", false);
      }
    },
    async GetByIdHubOrigin(context, id) {
      context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", true);
      try {
        const result = await axios({
          url: `${apiUrl}/in-hub-origin/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_IN_HUB_ORIGIN", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", false);
      }
    },
    async GetListInhubOiriginPosted(context) {
      context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", true);
      try {
        const result = await axios({
          url: `${apiUrl}/in-hub-origin/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_IN_HUB_ORIGIN", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", false);
      }
    },
    async GetListReceiptInhubOiriginPosted(context, id) {
      context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", true);
      try {
        const result = await axios({
          url: `${apiUrl}/in-hub-origin/list/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        // add no to data
        result.data.data.tbl_do_agentdetail.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit(
          "SET_LIST_RECEIPT_RECEIVE_AT_WAREHOUSE",
          result.data.data.tbl_do_agentdetail
        );
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", false);
      }
    },
    async UpdateStatusReceiptDoAgent(context, payload) {
      context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", true);

      console.log(payload);
      try {
        await axios({
          url: `${apiUrl}/in-hub-origin/receipt/${payload.idInHubOrigin}/${payload.idReceipt}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.dispatch("GetListInhubOiriginPosted");
        context.dispatch(
          "GetListReceiptInhubOiriginPosted",
          payload.idInHubOrigin
        );

        return true;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_ORIGIN", false);
      }
    },
  },
};

export default inHubOrigin;
