var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('ContentHeader',{attrs:{"header":"Setup","title":"Setup Ceisa"}}),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-card',{staticClass:"py-3 shadow-none border",attrs:{"loading":_vm.isLoading}},[_c('div',{staticClass:"card-body card-dashboard"},[_c('v-form',{ref:"initialReport",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Jenis ID Pemberitahu")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(
                                value,
                                'Jenis ID Pemberitahu'
                              );
                            },
                          ]},model:{value:(_vm.type_id),callback:function ($$v) {_vm.type_id=$$v},expression:"type_id"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("No ID Pemberitahu")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(
                                value,
                                'No ID Pemberitahu'
                              );
                            },
                          ]},model:{value:(_vm.no_id),callback:function ($$v) {_vm.no_id=$$v},expression:"no_id"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Nama Pemberitahu")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(
                                value,
                                'Nama Pemberitahu'
                              );
                            },
                          ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Alamat")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(value, 'Alamat');
                            },
                          ]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("No Izin")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(value, 'No Izin');
                            },
                          ]},model:{value:(_vm.no_izin),callback:function ($$v) {_vm.no_izin=$$v},expression:"no_izin"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Tgl Izin")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('AtomDatePicker',{model:{value:(_vm.date_izin),callback:function ($$v) {_vm.date_izin=$$v},expression:"date_izin"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Kode Negara")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(
                                value,
                                'Kode Negara'
                              );
                            },
                          ]},model:{value:(_vm.code_country),callback:function ($$v) {_vm.code_country=$$v},expression:"code_country"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Kode Kota")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(value, 'Kode Kota');
                            },
                          ]},model:{value:(_vm.code_city),callback:function ($$v) {_vm.code_city=$$v},expression:"code_city"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn bg-navy mr-1 py-2 px-5",attrs:{"type":"submit","data-save":"modal"}},[_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-outline-secondary mr-1 py-2 px-5",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")])])])])],1)])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }