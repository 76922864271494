<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment Out / Outbound" title="Outbound Manifest" />

    <section class="content">
      <div class="container-fluid">
        <div class="d-md-flex flex-wrap mb-3">
          <v-btn
            class="btn bg-navy mr-md-3 mb-3 mb-md-0"
            @click="handleModalForm(true)"
            v-if="isHub"
          >
            <i class="fa fa-plus"></i>
            Add Manifest
          </v-btn>
          <HandleExportManifest />
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <div class="row mb-5 align-items-center">
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-autocomplete
                      label="Business Unit"
                      :items="business_unit_list"
                      v-model="business_unit_active"
                      outlined
                      dense
                      hide-details="true"
                    />
                  </div>
                  <div class="col-md-7 col-lg-6 mb-3 mb-sm-0">
                    <DateRangePicker />
                  </div>
                </div>

                <br />

                <ul class="pl-0 nav nav-tabs">
                  <li class="nav-item" v-for="(item, i) in tab_list" :key="i">
                    <a
                      :class="`nav-link ${
                        item.title === tab_active && 'active fw-semibold'
                      }`"
                      @click="() => (tab_active = item.title)"
                    >
                      {{ item.title }}
                      <span :class="`badge ${item.color} badge-pill`">
                        {{ item.value }}
                      </span>
                    </a>
                  </li>
                </ul>

                <div class="card shadow-none border border-top-0 rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-end">
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="optionsTable.search"
                      />
                    </div>
                    <v-data-table
                      :headers="headers"
                      :items="setReportsByTab()"
                      :loading="isLoading"
                      :options.sync="optionsTable"
                      :search="optionsTable.search"
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        {{ moment(item.date).format("DD MMMM YYYY HH:mm:ss") }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="btn btn-outline-primary py-4"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="fw-light mr-1">Action</span>
                              <i class="fa-solid fa-chevron-down small"></i>
                            </v-btn>
                          </template>
                          <v-list min-width="150">
                            <v-list-item
                              @click="handleModalDetail(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fa-regular fa-eye small mr-2"></i>
                                <span class="small">Detail</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handlePrintDoManifest(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-print small mr-2"></i>
                                <span class="small">Print Do Manifest</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleExportManifestById(item)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-file-excel small mr-2"></i>
                                <span class="small">Export Manifest</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusPosted(item)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set to Posted</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusEntry(item)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set to Entry</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleUpdate(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Edit</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-if="modalForm" v-model="modalForm" persistent max-width="800">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
    <v-dialog v-model="modalDetail" persistent max-width="1200">
      <Detail @handleModalDetail="handleModalDetail" />
    </v-dialog>
    <v-dialog v-model="modalPrintDoManifest" persistent max-width="1000">
      <DocsDoManifest @handlePrintDoManifest="handlePrintDoManifest" />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import xlsx from "json-as-xlsx";

export default {
  name: "ShipmentOutOutbound",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
    Detail: () => import("./detail.vue"),
    DocsDoManifest: () => import("@/components/document/do-manifest/index.vue"),
    DateRangePicker: () =>
      import("@/components/molecules/date-range-picker.vue"),
    HandleExportManifest: () => import("./handle-export-manifest.vue"),
  },
  data() {
    return {
      moment,
      business_unit_list: ["All"],
      business_unit_active: "All",
      tab_active: "Entry",
      tab_list: [
        { title: "Entry", value: 0, color: "badge-info" },
        { title: "Posted", value: 0, color: "badge-success" },
        { title: "All", value: 0, color: "badge-dark" },
      ],
      headers: [
        // { text: "No", value: "no" },
        { text: "Date", value: "date" },
        { text: "Code", value: "code" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      modalForm: false,
      modalDetail: false,
      modalPrintDoManifest: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.outboundManifest.isLoading;
    },
    reports() {
      return this.$store.state.outboundManifest.reports;
    },
    isHub() {
      return this.$store.state.app.user.business_unit.type === "Hub"
        ? true
        : false;
    },
    optionsTable: {
      get() {
        return this.$store.state.outboundManifest.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_OUTBOUND_MANIFEST", value);
      },
    },
    start_date() {
      return this.$store.state.app.filter_range.start_date;
    },
    end_date() {
      return this.$store.state.app.filter_range.end_date;
    },
  },
  watch: {
    reports() {
      this.setTabFilter();
    },
    async start_date() {
      await this.$store.dispatch("FetchAllManifest");
      this.setTabFilter();
    },
    async end_date() {
      await this.$store.dispatch("FetchAllManifest");
      this.setTabFilter();
    },
  },
  methods: {
    handleModalForm(value) {
      if (value) this.$store.dispatch("FetchBeforeFormOutboundManifest", true);
      this.modalForm = value;
    },
    async handleUpdate(id) {
      await this.handleModalForm(true);
      await this.$store.dispatch("SetFormUpdateOutboundManifest", id);
    },
    handleModalDetail(value, id) {
      if (value) this.$store.dispatch("FetchAllManifestById", id);
      this.modalDetail = value;
    },
    handlePrintDoManifest(value, id) {
      if (value) this.$store.dispatch("FetchDocsDoManifest", id);
      this.modalPrintDoManifest = value;
    },
    setTabFilter() {
      this.tab_list.forEach((item) => {
        item.value = this.reports.filter(
          // set lower case to make it case insensitive
          (x) => x.status.toLowerCase() === item.title.toLowerCase()
        ).length;
      });

      this.tab_list[2].value = this.reports.length;

      for (const iterator of this.reports) {
        if (!this.business_unit_list.includes(iterator.business_unit.code)) {
          this.business_unit_list.push(iterator.business_unit.code);
        }
      }
    },
    setReportsByTab() {
      let result = [];
      if (this.tab_active === "All") {
        result = this.reports;
      } else {
        result = this.reports.filter(
          (x) => x.status.toLowerCase() === this.tab_active.toLowerCase()
        );
      }

      return result.filter(
        (x) =>
          x.business_unit.code === this.business_unit_active ||
          this.business_unit_active === "All"
      );
    },
    setStatusPosted(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to change status to Posted this DO status",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, set it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("UpdateStatusOutboundManifest", {
            id: item.id,
            status: "POSTED",
          });
        }
      });
    },
    setStatusEntry(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to change status to Entry this DO status",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, set it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("UpdateStatusOutboundManifest", {
            id: item.id,
            status: "ENTRY",
          });
        }
      });
    },
    async handleExportManifestById(item) {
      const content = await this.$store.dispatch(
        "ExportReceiptByIdOutboundManifest",
        item.id
      );
      item.code = item.code.replace(/\//g, "-");
      let data = [
        {
          sheet: `${item.code}`,
          columns: [
            { label: "NO_BARANG", value: "code_receipt" },
            { label: "NO_KANTONG", value: "code_bag" },
            { label: "ALMT PNGIRIM", value: "sender_address" },
            { label: "MARKING", value: "" },
            { label: "KOTA TUJUAN", value: "destination_city" },
            { label: "BERAT", value: "weight" },
            { label: "CUST", value: "" },
            { label: "NAMA_AGENT", value: "agent_name" },
          ],
          content: content,
        },
      ];

      let settings = {
        fileName: `ILOG Manifest Bandara - Data From ${item.code}`, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      };

      xlsx(data, settings); // Will download the excel file
    },
  },
  async mounted() {
    await this.$store.dispatch("FetchAllManifest");

    this.setTabFilter();
  },
};
</script>
