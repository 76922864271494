import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const threeLetterCode = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    form: {
      code_port: "",
      city: "",
      remarks: "",
      is_active: true,
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_THREE_LETTER_CODE(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_THREE_LETTER_CODE(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_THREE_LETTER_CODE(state, payload) {
      state.reports = payload;
    },
    SET_FORM_THREE_LETTER_CODE(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_THREE_LETTER_CODE(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllThreeLetterCode(context) {
      context.commit("SET_IS_LOADING_THREE_LETTER_CODE", true);
      try {
        const result = await axios({
          url: `${apiUrl}/shipping-port`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        // add no to data
        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_THREE_LETTER_CODE", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_THREE_LETTER_CODE", false);
      }
    },
    async CreateThreeLetterCode(context) {
      context.commit("SET_IS_LOADING_THREE_LETTER_CODE", true);

      try {
        const result = await axios({
          url: `${apiUrl}/shipping-port`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          title: "Success",
          icon: "success",
          text: result.data.message,
        });

        context.dispatch("GetAllThreeLetterCode");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_THREE_LETTER_CODE", false);
      }
    },
    async SetFormUpdateThreeLetterCode(context, id) {
      context.commit("SET_IS_LOADING_THREE_LETTER_CODE", true);
      try {
        const result = await axios({
          url: `${apiUrl}/shipping-port/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          code_port: result.data.data.code_port,
          city: result.data.data.city,
          remarks: result.data.data.remarks,
          is_active: result.data.data.is_active,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_THREE_LETTER_CODE", false);
      }
    },
    async UpdateThreeLetterCode(context, id) {
      context.commit("SET_IS_LOADING_THREE_LETTER_CODE", true);

      try {
        const result = await axios({
          url: `${apiUrl}/shipping-port/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          title: "Success",
          icon: "success",
          text: result.data.message,
        });

        context.dispatch("GetAllThreeLetterCode");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_THREE_LETTER_CODE", false);
      }
    },
    async DeleteThreeLetterCode(context, id) {
      context.commit("SET_IS_LOADING_THREE_LETTER_CODE", true);

      try {
        const result = await axios({
          url: `${apiUrl}/shipping-port/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          title: "Success",
          icon: "success",
          text: result.data.message,
        });

        context.dispatch("GetAllThreeLetterCode");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_THREE_LETTER_CODE", false);
      }
    },
  },
};

export default threeLetterCode;
