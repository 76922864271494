var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_vm._m(0),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3"},[_c('v-card',{staticClass:"shadow-none border py-4 px-3 h-100",attrs:{"loading":_vm.isLoading}},[_c('div',{staticClass:"card-body"},[_c('v-form',{ref:"initialReport",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h4',{staticClass:"fw-bold"},[_vm._v("Check No Resi")]),_c('div',{staticClass:"row align-items-center mt-3"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('label',{staticClass:"form-label small"},[_vm._v("No Resi")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"No Resi","rules":[
                        (value) => {
                          return _vm.genericRequiredRule(value, 'No Resi');
                        },
                      ]},model:{value:(_vm.noResi),callback:function ($$v) {_vm.noResi=$$v},expression:"noResi"}})],1),_c('div',{staticClass:"col-12 col-lg-2 mt-3 mt-lg-0"},[_c('v-btn',{staticClass:"btn bg-navy px-10",attrs:{"type":"submit","loading":_vm.isLoading}},[_vm._v(" Cek Resi ")])],1)])])],1)])],1)]),(_vm.result)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-card',{staticClass:"shadow-none border mt-5 mb-3"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"mb-5 fw-medium"},[_vm._v("Result from "+_vm._s(_vm.result.code))]),_c('table',{staticClass:"mb-10"},_vm._l(([
                    {
                      key: 'Pengirim',
                      value: _vm.result.sender_name,
                      value2: _vm.result.sender_address,
                    },
                    {
                      key: 'Penerima',
                      value: _vm.result.receiver_name,
                      value2: _vm.result.receiver_address,
                    },
                  ]),function(item,i){return _c('tr',{key:i,staticStyle:{"vertical-align":"top"}},[_c('th',{staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(item.key))]),_c('th',{staticStyle:{"min-width":"20px"}},[_vm._v(":")]),_c('td',[_vm._v(" "+_vm._s(item.value)+" "),_c('br'),_vm._v(" "+_vm._s(item.value2)+" ")])])}),0),_c('table',{staticClass:"table"},[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Remark")])]),_vm._l((_vm.result.receipt_status),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("DD MMMM YYYY - HH:mm"))+" ")]),_c('td',[_vm._v(_vm._s(item.remark_external))])])})],2),_c('br'),_c('p',[_vm._v("Received Image")]),(_vm.result.received_img)?_c('a',{attrs:{"href":_vm.staticUrlImage(_vm.result.received_img),"target":"_blank"}},[_c('img',{attrs:{"src":_vm.staticUrlImage(_vm.result.received_img),"alt":"","width":"200"}})]):_c('p',{staticClass:"text-muted"},[_vm._v("Not Upload Received Image")])])])],1)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-2 mt-3"},[_c('div',{staticClass:"col-sm-6"},[_c('h1',[_vm._v("Check Resi")])])])])])
}]

export { render, staticRenderFns }