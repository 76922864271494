import Vue from "vue";
import App from "./App.vue";

import vuetify from "./plugins/vuetify";
import "./plugins/vuetify-money";
import "toastify-js/src/toastify.css";

import router from "./router";
import store from "./store";

import VueHtmlToPaper from "vue-html-to-paper";
import CityDestination from './components/form/city-destination-select.vue'

const options = {
  name: "_blank",
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "/docs.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

Vue.component('city-destination-select', CityDestination)

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  VueHtmlToPaper,
  render: (h) => h(App),
}).$mount("#app");
