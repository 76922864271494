<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment Out / Outbound" title="Outbound Central" />

    <section class="content">
      <div class="container-fluid">
        <div class="d-md-flex flex-wrap mb-3">
          <v-btn
            class="btn bg-navy mb-3 mb-md-0"
            @click="handleModalForm(true)"
            v-if="isHub"
          >
            <i class="fa fa-plus"></i>
            Add Central
          </v-btn>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <div class="row mb-5 align-items-center">
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-autocomplete
                      label="Business Unit"
                      :items="business_unit_list"
                      v-model="business_unit_active"
                      @change="setReportsByTab"
                      outlined
                      dense
                      hide-details="true"
                    />
                  </div>
                  <div class="col-md-7 col-lg-6 mb-3 mb-sm-0">
                    <DateRangePicker />
                  </div>
                </div>
                <br />

                <ul class="pl-0 nav nav-tabs">
                  <li class="nav-item" v-for="(item, i) in tab_list" :key="i">
                    <a
                      :class="`nav-link ${
                        item.title === tab_active && 'active fw-semibold'
                      }`"
                      @click="() => (tab_active = item.title)"
                    >
                      {{ item.title }}
                      <span :class="`badge ${item.color} badge-pill`">
                        {{ item.value }}
                      </span>
                    </a>
                  </li>
                </ul>

                <div class="card shadow-none border border-top-0 rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-end">
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="optionsTable.search"
                      />
                    </div>
                    <v-data-table
                      :headers="headers"
                      :items="setReportsByTab()"
                      :loading="isLoading"
                      :options.sync="optionsTable"
                      :search="optionsTable.search"
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        {{ moment(item.date).format("DD MMMM YYYY | HH:mm") }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="btn btn-outline-primary py-4"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="fw-light mr-1">Action</span>
                              <i class="fa-solid fa-chevron-down small"></i>
                            </v-btn>
                          </template>
                          <v-list min-width="150">
                            <v-list-item
                              @click="handleModalDetail(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fa-regular fa-eye small mr-2"></i>
                                <span class="small">Detail</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handlePrintDoCentral(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-print small mr-2"></i>
                                <span class="small">Print Do Central</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handleExportCeisa(item)">
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-file-excel small mr-2"></i>
                                <span class="small">Export Ceisa</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusToEntry(item.id)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set to Entry</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="updateStatusCustomClearance(item.id)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">
                                  Update Status Custom Clearance
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusToPosted(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set to Posted</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleUpdate(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Edit</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleModalUploadBillingTPS(true, item)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Upload Billing TPS</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleModalUploadBTBTPS(true, item)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Upload BTB TPS</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-if="modalForm" v-model="modalForm" persistent max-width="800">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>

    <v-dialog v-model="modalDetail" persistent max-width="1000">
      <Detail @handleModalDetail="handleModalDetail" />
    </v-dialog>

    <v-dialog v-model="modalUploadBillingTPS" persistent max-width="600">
      <UploadBillingTPS
        @handleModalUploadBillingTPS="handleModalUploadBillingTPS"
      />
    </v-dialog>

    <v-dialog v-model="modalUploadBTBTPS" persistent max-width="600">
      <UploadBTBTPS @handleModalUploadBTBTPS="handleModalUploadBTBTPS" />
    </v-dialog>

    <v-dialog v-model="modalPrintDoCentral" persistent max-width="1200">
      <DocsDoCentral @handlePrintDoCentral="handlePrintDoCentral" />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import xlsx from "json-as-xlsx";

export default {
  name: "ShipmentOutOutbound",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
    Detail: () => import("./detail.vue"),
    UploadBillingTPS: () => import("./upload-billing-tps.vue"),
    UploadBTBTPS: () => import("./upload-btb-tps.vue"),
    DocsDoCentral: () => import("@/components/document/do-central/index.vue"),
    DateRangePicker: () =>
      import("@/components/molecules/date-range-picker.vue"),
  },
  data() {
    return {
      moment,
      business_unit_list: ["All"],
      business_unit_active: "All",
      tab_active: "Entry",
      tab_list: [
        { title: "Entry", value: 0, color: "badge-info" },
        { title: "Posted", value: 0, color: "badge-success" },
        { title: "All", value: 0, color: "badge-dark" },
      ],
      headers: [
        { text: "No", value: "no" },
        { text: "Code", value: "code" },
        { text: "Date", value: "date" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      modalForm: false,
      modalDetail: false,
      modalUploadBillingTPS: false,
      modalUploadBTBTPS: false,
      modalPrintDoCentral: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.outboundCentral.isLoading;
    },
    reports() {
      return this.$store.state.outboundCentral.reports;
    },
    isHub() {
      return this.$store.state.app.user.business_unit.type === "Hub"
        ? true
        : false;
    },
    optionsTable: {
      get() {
        return this.$store.state.outboundCentral.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_OUTBOUND_CENTRAL", value);
      },
    },
    start_date() {
      return this.$store.state.app.filter_range.start_date;
    },
    end_date() {
      return this.$store.state.app.filter_range.end_date;
    },
  },
  watch: {
    reports() {
      this.setTabFilter();
    },
    async start_date() {
      await this.$store.dispatch("FetchOutboundCentral");
      this.setTabFilter();
    },
    async end_date() {
      await this.$store.dispatch("FetchOutboundCentral");
      this.setTabFilter();
    },
  },
  methods: {
    setTabFilter() {
      this.tab_list.forEach((item) => {
        item.value = this.reports.filter(
          // set lower case to make it case insensitive
          (x) => x.status.toLowerCase() === item.title.toLowerCase()
        ).length;
      });

      this.tab_list[2].value = this.reports.length;

      for (const iterator of this.reports) {
        if (!this.business_unit_list.includes(iterator.business_unit.code)) {
          this.business_unit_list.push(iterator.business_unit.code);
        }
      }
    },
    setReportsByTab() {
      let result = [];
      if (this.tab_active === "All") {
        result = this.reports;
      } else {
        result = this.reports.filter(
          (x) => x.status.toLowerCase() === this.tab_active.toLowerCase()
        );
      }

      return result.filter(
        (x) =>
          x.business_unit.code === this.business_unit_active ||
          this.business_unit_active === "All"
      );
    },
    handleModalForm(value) {
      if (value) this.$store.dispatch("SetBeforeFormOutbound");
      this.modalForm = value;
    },
    handleModalDetail(value, id) {
      if (value) this.$store.dispatch("FetchOutboundCentralById", id);
      this.modalDetail = value;
    },
    handlePrintDoCentral(value, id) {
      if (value) this.$store.dispatch("FetchDocsDoCentral", id);
      this.modalPrintDoCentral = value;
    },
    setStatusToEntry(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will set this DO to entry!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, set to entry!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("UpdateOutboundCentralEntry", id);
        }
      });
    },
    setStatusToPosted(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will set this DO to posted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, set to posted!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("UpdateOutboundCentralPosted", id);
        }
      });
    },
    updateStatusCustomClearance(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will update status custom clearance",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update status!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(
            "UpdateStatusCustomClearanceOutboundCentral",
            id
          );
        }
      });
    },
    async handleUpdate(id) {
      await this.handleModalForm(true);

      await this.$store.commit("SET_IS_UPDATE_OUTBOUND_CENTRAL", id);
      await this.$store.dispatch("SetFormOutboundCentral", id);
    },
    handleModalUploadBillingTPS(value, payload) {
      if (value)
        this.$store.dispatch("SetFormUploadBillingTPSOutboundCentral", payload);
      this.modalUploadBillingTPS = value;
    },
    handleModalUploadBTBTPS(value, payload) {
      if (value)
        this.$store.dispatch("SetFormUploadBTBTPSOutboundCentral", payload);
      this.modalUploadBTBTPS = value;
    },
    async handleExportCeisa(item) {
      const content = await this.$store.dispatch(
        "ExportReceiptForCeisaByIdOutboundCentral",
        item.id
      );

      if (content.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Data not found!",
        });
        return;
      }

      let data = [
        {
          sheet: `${item.code}`,
          columns: [
            { label: "NO_BARANG", value: "NO_BARANG" },
            { label: "NO_KANTONG", value: "NO_KANTONG" },
            { label: "KD_JNS_ANGKUT", value: "KD_JNS_ANGKUT" },
            { label: "NM_PENGANGKUT", value: "NM_PENGANGKUT" },
            { label: "NO_FLIGHT", value: "NO_FLIGHT" },
            { label: "KD_PEL_MUAT", value: "KD_PEL_MUAT" },
            { label: "KD_PEL_BONGKAR", value: "KD_PEL_BONGKAR" },
            { label: "KD_GUDANG", value: "KD_GUDANG" },
            { label: "NO_INVOICE", value: "NO_INVOICE" },
            { label: "TGL_INVOICE", value: "TGL_INVOICE" },
            { label: "KD_NEGARA_ASAL", value: "KD_NEGARA_ASAL" },
            { label: "JML_BRG", value: "JML_BRG" },
            { label: "NO_MASTER_BLAWB", value: "NO_MASTER_BLAWB" },
            { label: "TGL_MASTER_BLAWB", value: "TGL_MASTER_BLAWB" },
            { label: "NO_HOUSE_BLAWB", value: "NO_HOUSE_BLAWB" },
            { label: "TGL_HOUSE_BLAWB", value: "TGL_HOUSE_BLAWB" },
            { label: "KD_NEG_PENGIRIM", value: "KD_NEG_PENGIRIM" },
            { label: "NMPENGIRIM", value: "NMPENGIRIM" },
            { label: "AL_PENGIRIM", value: "AL_PENGIRIM" },
            { label: "KODE_KOTA_PENGIRIM", value: "KODE_KOTA_PENGIRIM" },
            { label: "SHIPPER_STATUS", value: "SHIPPER_STATUS" },
            { label: "STATUS_PENGIRIM", value: "STATUS_PENGIRIM" },
            { label: "JNS_ID_PENERIMA", value: "JNS_ID_PENERIMA" },
            { label: "NO_ID_PENERIMA", value: "NO_ID_PENERIMA" },
            { label: "NMPENERIMA", value: "NMPENERIMA" },
            { label: "ALPENERIMA", value: "ALPENERIMA" },
            { label: "TELP_PENERIMA", value: "TELP_PENERIMA" },
            { label: "KODE_KOTA_PENERIMA", value: "KODE_KOTA_PENERIMA" },
            { label: "KOTA_PENERIMA", value: "KOTA_PENERIMA" },
            { label: "JNS_ID_PEMBERITAHU", value: "JNS_ID_PEMBERITAHU" },
            { label: "NO_ID_PEMBERITAHU", value: "NO_ID_PEMBERITAHU" },
            { label: "NM_PEMBERITAHU", value: "NM_PEMBERITAHU" },
            { label: "AL_PEMBERITAHU", value: "AL_PEMBERITAHU" },
            { label: "NO_IZIN_PEMBERITAHU", value: "NO_IZIN_PEMBERITAHU" },
            { label: "TGL_IZIN_PEMBERITAHU", value: "TGL_IZIN_PEMBERITAHU" },
            { label: "NDPBM", value: "NDPBM" },
            { label: "NILAI BARANG IDR", value: "NILAI BARANG IDR" },
            { label: "FOB", value: "FOB" },
            { label: "ASURANSI", value: "ASURANSI" },
            { label: "FREIGHT", value: "FREIGHT" },
            { label: "CIF", value: "CIF" },
            { label: "NETTO", value: "NETTO" },
            { label: "BRUTO", value: "BRUTO" },
            { label: "TOT_DIBAYAR", value: "TOT_DIBAYAR" },
            { label: "NPWP_BILLING", value: "NPWP_BILLING" },
            { label: "NAMA_BILLING", value: "NAMA_BILLING" },
            { label: "NO_HOUSE_BLAWB", value: "NO_HOUSE_BLAWB" },
            { label: "SERI_BRG", value: "SERI_BRG" },
            { label: "HS_CODE", value: "HS_CODE" },
            { label: "UR_BRG", value: "UR_BRG" },
            { label: "CODE_URAIAN BRG", value: "CODE_URAIAN BRG" },
            { label: "KD_NEG_ASAL", value: "KD_NEG_ASAL" },
            { label: "JML_KMS", value: "JML_KMS" },
            { label: "JNS_KMS", value: "JNS_KMS" },
            { label: "CIF", value: "CIF" },
            { label: "KD_SAT_HRG", value: "KD_SAT_HRG" },
            { label: "JML_SAT_HRG", value: "JML_SAT_HRG" },
            { label: "FL_BEBAS", value: "FL_BEBAS" },
            { label: "NO_SKEP", value: "NO_SKEP" },
            { label: "TGL_SKEP", value: "TGL_SKEP" },
            { label: "KD_TARIF", value: "KD_TARIF" },
            { label: "KD_SAT_TARIF", value: "KD_SAT_TARIF" },
            { label: "JML_SAT", value: "JML_SAT" },
            { label: "BM_TRF(%)", value: "BM_TRF(%)" },
            { label: "PPH_TRF(%)", value: "PPH_TRF(%)" },
            { label: "PPN_TRF(%)", value: "PPN_TRF(%)" },
            { label: "PPNBM_TRF(%)", value: "PPNBM_TRF(%)" },
            { label: "DETIL_BM", value: "DETIL_BM" },
            { label: "DETIL_PPH", value: "DETIL_PPH" },
            { label: "DETIL_PPN", value: "DETIL_PPN" },
            { label: "DETIL_PPNBM", value: "DETIL_PPNBM" },
            { label: "HEADER_BM", value: "HEADER_BM" },
            { label: "HEADER_PPH", value: "HEADER_PPH" },
            { label: "HEADER_PPN", value: "HEADER_PPN" },
            { label: "HEADER_PPNBM", value: "HEADER_PPNBM" },
            { label: "NILAI_DETIL_BM", value: "NILAI_DETIL_BM" },
            { label: "NILAI_DETIL_PPH", value: "NILAI_DETIL_PPH" },
            { label: "NILAI_DETIL_PPN", value: "NILAI_DETIL_PPN" },
            { label: "NILAI_DETIL_PPNBM", value: "NILAI_DETIL_PPNBM" },
            { label: "NILAI_HEADER_BM", value: "NILAI_HEADER_BM" },
            { label: "NILAI_HEADER_PPH", value: "NILAI_HEADER_PPH" },
            { label: "NILAI_HEADER_PPN", value: "NILAI_HEADER_PPN" },
            { label: "NILAI_HEADER_PPNBM", value: "NILAI_HEADER_PPNBM" },
            { label: "NILAI_FOB", value: "NILAI_FOB" },
            { label: "BULAT_ASURANSI", value: "BULAT_ASURANSI" },
            { label: "BULAT_FREIGHT", value: "BULAT_FREIGHT" },
            { label: "BULAT_CIF", value: "BULAT_CIF" },
            { label: "BULAT_NETTO", value: "BULAT_NETTO" },
            { label: "BULAT_BRUTO", value: "BULAT_BRUTO" },
            { label: "NILAI_TOTAL_BAYAR", value: "NILAI_TOTAL_BAYAR" },
            { label: "NILAI_ASURANSI", value: "NILAI_ASURANSI" },
            { label: "NILAI_FREIGHT", value: "NILAI_FREIGHT" },
            { label: "NILAI_CIF", value: "NILAI_CIF" },
            { label: "NILAI_NETTO", value: "NILAI_NETTO" },
            { label: "NILAI_BRUTO", value: "NILAI_BRUTO" },
            { label: "FUNCTION_STATUS_TOKO", value: "FUNCTION_STATUS_TOKO" },
            { label: "NAMA_AGENT", value: "agent_name" },
          ],
          content: content,
        },
      ];

      let settings = {
        fileName: `ILOG Ceisa - Data From Outbound Central ${item.code}`, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        // add border to the table
        style: {
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        },
      };

      xlsx(data, settings); // Will download the excel file
    },
  },
  async mounted() {
    await this.$store.dispatch("FetchOutboundCentral");

    this.setTabFilter();
  },
};
</script>
