import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const form = {
  code: "",
  id_courier: "",
  vehicle_code: "",
  remarks: "",
  details: [],
};

const delivery = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    list_business_unit_kurir: [],
    list_kurir: [],
    list_sortir: [],
    form: {
      ...form,
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_DELIVERY(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_DELIVERY(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_DELIVERY(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_DELIVERY(state, payload) {
      state.report = payload;
    },
    SET_LIST_BUSINESS_UNIT_KURIR_DELIVERY(state, payload) {
      state.list_business_unit_kurir = payload;
    },
    SET_LIST_KURIR_DELIVERY(state, payload) {
      state.list_kurir = payload;
    },
    SET_LIST_SORTIR_DELIVERY(state, payload) {
      state.list_sortir = payload;
    },
    SET_FORM_DELIVERY(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_DELIVERY(state) {
      state.form = { ...form };
    },
    SET_IS_UPDATE_DELIVERY(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetDelivery(context) {
      context.commit("SET_IS_LOADING_DELIVERY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/delivery?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_DELIVERY", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_DELIVERY", false);
      }
    },
    async GetDeliveryById(context, id) {
      context.commit("SET_IS_LOADING_DELIVERY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/delivery/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_DELIVERY", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_DELIVERY", false);
      }
    },
    async FetchBeforeFormDelivery(context) {
      context.commit("SET_IS_LOADING_DELIVERY", true);
      try {
        const businessUnitKurir = await axios({
          url: `${apiUrl}/business-unit/list/kurir`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit(
          "SET_LIST_BUSINESS_UNIT_KURIR_DELIVERY",
          businessUnitKurir.data.data
        );

        const sortirPosted = await axios({
          url: `${apiUrl}/sortir/list-sortir-posted`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_SORTIR_DELIVERY", sortirPosted.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_DELIVERY", false);
      }
    },
    async FetchKurirByIdBusinessUnitDelivery(context, id) {
      context.commit("SET_IS_LOADING_DELIVERY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/user/list/business-unit/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_KURIR_DELIVERY", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_DELIVERY", false);
      }
    },
    async CreateDelivery(context) {
      context.commit("SET_IS_LOADING_DELIVERY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/delivery`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetDelivery");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_DELIVERY", false);
      }
    },
    async UpdateStatusDelivery(context, payload) {
      context.commit("SET_IS_LOADING_DELIVERY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/delivery/status/${payload.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            status: payload.status,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetDelivery");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_DELIVERY", false);
      }
    },
  },
};

export default delivery;
