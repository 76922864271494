<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Welcome 👋</h1>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 col-6">
            <div class="small-box bg-info">
              <div class="inner">
                <h3>{{ totalHubOrigin }}</h3>
                <p>Hub Origin</p>
              </div>
              <router-link
                to="/shipment-out/in-hub-origin"
                class="small-box-footer"
                >More info <i class="fas fa-arrow-circle-right"></i
              ></router-link>
            </div>
          </div>

          <div class="col-lg-2 col-6">
            <div class="small-box bg-primary">
              <div class="inner">
                <h3>{{ totalReceiptProcess }}</h3>
                <p>Process</p>
              </div>
              <router-link
                to="/shipment-out/receipt-list?status=In Progress"
                class="small-box-footer"
                >More info <i class="fas fa-arrow-circle-right"></i
              ></router-link>
            </div>
          </div>

          <div class="col-lg-2 col-6">
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>{{ totalHubDestination }}</h3>
                <p>Hub Destination</p>
              </div>
              <router-link
                to="/shipment-in/in-hub-destination"
                class="small-box-footer"
                >More info <i class="fas fa-arrow-circle-right"></i
              ></router-link>
            </div>
          </div>

          <div class="col-lg-2 col-6">
            <div class="small-box bg-info">
              <div class="inner">
                <h3>{{ totalReceiptDelivered }}</h3>
                <p>Delivered</p>
              </div>
              <router-link
                to="/shipment-out/receipt-list?status=Delivered"
                class="small-box-footer"
                >More info <i class="fas fa-arrow-circle-right"></i
              ></router-link>
            </div>
          </div>

          <div class="col-lg-2 col-6">
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>{{ totalReceiptFailed }}</h3>
                <p>Failed</p>
              </div>
              <router-link
                to="/shipment-out/receipt-list?status=Failed"
                class="small-box-footer"
                >More info <i class="fas fa-arrow-circle-right"></i
              ></router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header font-weight-bold">Recent</div>
              <div class="card-body">
                <div class="d-flex justify-content-end">
                  <v-text-field
                    class="input-search"
                    label="Cari"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    v-model="search"
                    dense
                  />
                </div>
                <v-data-table
                  :headers="headers"
                  :items="receiptFailed"
                  :search="search"
                >
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip color="error" dark small>
                      {{ item.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DashboardPage",
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Resi", value: "code" },
        { text: "Receiver Name", value: "receiver_name" },
        { text: "Receiver Address", value: "receiver_address" },
        { text: "Status", value: "status" },
        { text: "Remarks", value: "failed_delivery" },
      ],
      search: "",
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.dashboard.isLoading;
    },
    totalHubOrigin() {
      return this.$store.state.dashboard.totalHubOrigin;
    },
    totalReceiptProcess() {
      return this.$store.state.dashboard.totalReceiptProcess;
    },
    totalHubDestination() {
      return this.$store.state.dashboard.totalHubDestination;
    },
    totalReceiptDelivered() {
      return this.$store.state.dashboard.totalReceiptDelivered;
    },
    totalReceiptFailed() {
      return this.$store.state.dashboard.totalReceiptFailed;
    },
    receiptFailed() {
      return this.$store.state.dashboard.receiptFailed;
    },
  },
  mounted() {
    this.$store.dispatch("GetDashboard");
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .col-lg-2 {
    -ms-flex: 0 0 calc(100% / 5) !important;
    flex: 0 0 calc(100% / 5) !important;
    max-width: calc(100% / 5) !important;
  }
}
</style>
