<template>
  <div class="content-wrapper">
    <ContentHeader header="Setup" title="Setup Ceisa" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <v-card class="py-3 shadow-none border" :loading="isLoading">
              <div class="card-body card-dashboard">
                <v-form ref="initialReport" @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Jenis ID Pemberitahu</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="type_id"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(
                                  value,
                                  'Jenis ID Pemberitahu'
                                );
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>No ID Pemberitahu</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="no_id"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(
                                  value,
                                  'No ID Pemberitahu'
                                );
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Nama Pemberitahu</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="name"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(
                                  value,
                                  'Nama Pemberitahu'
                                );
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Alamat</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="address"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(value, 'Alamat');
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>No Izin</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="no_izin"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(value, 'No Izin');
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Tgl Izin</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <AtomDatePicker v-model="date_izin" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Kode Negara</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="code_country"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(
                                  value,
                                  'Kode Negara'
                                );
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Kode Kota</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            v-model="code_city"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(value, 'Kode Kota');
                              },
                            ]"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <button
                        type="submit"
                        data-save="modal"
                        class="btn bg-navy mr-1 py-2 px-5"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        class="btn btn-outline-secondary mr-1 py-2 px-5"
                        @click="handleCancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </v-form>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";

export default {
  name: "SetupCeisaPage",
  mixins: [ValidationRules],
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    AtomDatePicker: () => import("@/components/atoms/date-picker.vue"),
  },
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.state.ceisa.isLoading;
    },
    type_id: {
      get() {
        return this.$store.state.ceisa.report.type_id;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "type_id",
          value,
        });
      },
    },
    no_id: {
      get() {
        return this.$store.state.ceisa.report.no_id;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "no_id",
          value,
        });
      },
    },
    name: {
      get() {
        return this.$store.state.ceisa.report.name;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "name",
          value,
        });
      },
    },
    address: {
      get() {
        return this.$store.state.ceisa.report.address;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "address",
          value,
        });
      },
    },
    no_izin: {
      get() {
        return this.$store.state.ceisa.report.no_izin;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "no_izin",
          value,
        });
      },
    },
    date_izin: {
      get() {
        return this.$store.state.ceisa.report.date_izin;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "date_izin",
          value,
        });
      },
    },
    code_country: {
      get() {
        return this.$store.state.ceisa.report.code_country;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "code_country",
          value,
        });
      },
    },
    code_city: {
      get() {
        return this.$store.state.ceisa.report.code_city;
      },
      set(value) {
        this.$store.commit("SET_FORM_REPORT_CEISA", {
          key: "code_city",
          value,
        });
      },
    },
  },
  methods: {
    handleCancel() {
      this.$store.dispatch("GetCeisa");
    },
    handleSubmit() {
      this.$refs.initialReport.validate();

      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("UpdateCeisa");
      }
    },
  },
  mounted() {
    this.$store.dispatch("GetCeisa");
  },
};
</script>
