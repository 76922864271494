<template>
  <div class="content-wrapper">
    <ContentHeader header="Setup" title="Rate City List" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <v-btn
                  class="btn bg-navy mb-3 mb-md-0"
                  @click="handleModalForm(true)"
                  v-if="isAdministrator"
                >
                  <i class="fa fa-plus"></i>
                  Tambah
                </v-btn>
                <div class="d-flex justify-content-end">
                  <v-text-field
                    class="input-search"
                    label="Cari"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    dense
                    v-model="optionsTable.search"
                  />
                </div>
                <v-data-table
                  :headers="headers"
                  :items="reports"
                  :loading="isLoading"
                  :server-items-length="reportsTotal"
                  :options.sync="optionsTable"
                  :search="optionsTable.search"
                  @update:options="loadItems"
                >
                  <template v-slot:[`item.price`]="{ item }">
                    {{ format3Digit(item.price) }}
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      icon
                      color="white"
                      class="bg-warning mr-2"
                      @click="handleUpdate(item.id)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="white"
                      class="bg-danger"
                      @click="handleDelete(item.id)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalForm" persistent max-width="700">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
  </div>
</template>

<script>
import format3Digit from "@/utils/format-3digit";
import Swal from "sweetalert2";

export default {
  name: "SetupRateCityPage",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
  },
  data() {
    return {
      headers: [
        // { text: "Code", value: "code" },
        { text: "From City", value: "tbm_city.name", sortable: false },
        { text: "To Province", value: "province", sortable: false },
        { text: "Destination", value: "destination", sortable: false },
        // { text: "To City", value: "city", sortable: false },
        // { text: "To District", value: "district", sortable: false },
        { text: "Kode Reg", value: "code_reg", sortable: false },
        { text: "Price", value: "price", align: "right", sortable: false },
        { text: "Day Min Reg", value: "day_min_reg", sortable: false },
        { text: "Day Max Reg", value: "day_max_reg", sortable: false },
        // { text: "Kode Pos", value: "post_code", sortable: false },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      modalForm: false,
      format3Digit,
    };
  },
  computed: {
    isAdministrator() {
      return this.$store.state.app.user.business_unit.type === "Administrator"
        ? true
        : false;
    },
    isLoading() {
      return this.$store.state.rateCity.isLoading;
    },
    reports() {
      return this.$store.state.rateCity.reports;
    },
    reportsTotal() {
      return this.$store.state.rateCity.reportsTotal;
    },
    optionsTable: {
      get() {
        return this.$store.state.rateCity.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_RATE_CITY", value);
      },
    },
  },
  methods: {
    handleModalForm(value) {
      if (value) this.$store.dispatch("FetchBeforeFormRateCity");
      this.modalForm = value;
    },
    handleUpdate(id) {
      this.$store.dispatch("SetFormUpdateRateCity", id);
      this.$store.commit("SET_IS_UPDATE_RATE_CITY", id);
      this.handleModalForm(true);
    },
    handleDelete(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DeleteRateCity", id);
        }
      });
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      this.$store.dispatch("GetAllRateCity", {
        limit: itemsPerPage,
        page,
        sortBy,
        keyword: this.optionsTable.search,
      });
    },
  },
  mounted() {
    this.$store.dispatch("GetAllRateCity");
  },
};
</script>
