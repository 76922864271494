import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const form = {
  id: "",
  receipt_code: "",
  received_name: "",
  received_status: "",
  received_img: "",
  remarks: "",
};

const delivered = {
  state: {
    isLoading: false,
    list_sortir: [],
    reports: [],
    form: {
      ...form,
    },
  },
  mutations: {
    SET_IS_LOADING_DELIVERED(state, payload) {
      state.isLoading = payload;
    },
    SET_LIST_SORTIR_DELIVERED(state, payload) {
      state.list_sortir = payload;
    },
    SET_REPORTS_DELIVERED(state, payload) {
      state.reports = payload;
    },
    SET_FORM_DELIVERED(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_DELIVERED(state) {
      state.form.receipt_code = "";
      state.form.received_name = "";
      state.form.received_status = "";
      state.form.remarks = "";
    },
  },
  actions: {
    async FetchSortirDelivered(context) {
      context.commit("SET_IS_LOADING_DELIVERED", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir/list-sortir-posted-kurir`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_SORTIR_DELIVERED", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_DELIVERED", false);
      }
    },
    async FetchSortirDetailDelivered(context, id) {
      context.commit("SET_IS_LOADING_DELIVERED", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir/list-sortir-posted-kurir/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_DELIVERED", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_DELIVERED", false);
      }
    },
    async DeliveredSuccess(context) {
      context.commit("SET_IS_LOADING_DELIVERED", true);
      try {
        const payload = context.state.form;

        await axios({
          url: `${apiUrl}/delivered/success/${payload.receipt_code}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
            "Content-Type": "multipart/form-data",
          },
          data: payload,
        });

        context.dispatch("FetchSortirDelivered");
        context.dispatch("FetchSortirDetailDelivered", payload.id);

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Status berhasil diubah",
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_DELIVERED", false);
      }
    },
    async DeliveredFailed(context) {
      context.commit("SET_IS_LOADING_DELIVERED", true);
      try {
        const payload = context.state.form;

        await axios({
          url: `${apiUrl}/delivered/failed/${payload.receipt_code}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: payload,
        });

        context.dispatch("FetchSortirDelivered");
        context.dispatch("FetchSortirDetailDelivered", payload.id);

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Status berhasil diubah",
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_DELIVERED", false);
      }
    },
  },
};

export default delivered;
