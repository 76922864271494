import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const form = {
  code: "",
  remarks: "",
  sortir_name: "",
  details: [],
};

const sortir = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    list_receipt: [],
    form: {
      ...form,
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_SORTIR(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_SORTIR(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_SORTIR(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_SORTIR(state, payload) {
      state.report = payload;
    },
    SET_LIST_RECEIPT_SORTIR(state, payload) {
      state.list_receipt = payload;
    },
    SET_FORM_SORTIR(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_SORTIR(state) {
      state.form = { ...form };
    },
    SET_IS_UPDATE_SORTIR(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetSortir(context) {
      context.commit("SET_IS_LOADING_SORTIR", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_SORTIR", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_SORTIR", false);
      }
    },
    async GetSortirById(context, id) {
      context.commit("SET_IS_LOADING_SORTIR", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_SORTIR", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_SORTIR", false);
      }
    },
    async FetchBeforeFormSortir(context) {
      context.commit("SET_IS_LOADING_SORTIR", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir/list-receipt`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_RECEIPT_SORTIR", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_SORTIR", false);
      }
    },
    async CreateSortir(context) {
      context.commit("SET_IS_LOADING_SORTIR", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetSortir");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_SORTIR", false);
      }
    },
    async UpdateStatusSortir(context, payload) {
      context.commit("SET_IS_LOADING_SORTIR", true);
      try {
        const result = await axios({
          url: `${apiUrl}/sortir/status/${payload.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            status: payload.status,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetSortir");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_SORTIR", false);
      }
    },
  },
};

export default sortir;
