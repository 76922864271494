import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import catchUnauthorized from "@/utils/catch-unauthorized";

const dashboard = {
  state: {
    isLoading: false,
    totalHubOrigin: "",
    totalReceiptProcess: "",
    totalHubDestination: "",
    totalReceiptDelivered: "",
    totalReceiptFailed: "",
    receiptFailed: [],
  },
  mutations: {
    SET_LOADING_DASHBOARD(state, payload) {
      state.isLoading = payload;
    },
    SET_TOTAL_HUB_ORIGIN_DASHBOARD(state, payload) {
      state.totalHubOrigin = payload;
    },
    SET_TOTAL_RECEIPT_PROCESS_DASHBOARD(state, payload) {
      state.totalReceiptProcess = payload;
    },
    SET_TOTAL_HUB_DESTINATION_DASHBOARD(state, payload) {
      state.totalHubDestination = payload;
    },
    SET_TOTAL_RECEIPT_DELIVERED_DASHBOARD(state, payload) {
      state.totalReceiptDelivered = payload;
    },
    SET_TOTAL_RECEIPT_FAILED_DASHBOARD(state, payload) {
      state.totalReceiptFailed = payload;
    },
    SET_RECEIPT_FAILED_DASHBOARD(state, payload) {
      state.receiptFailed = payload;
    },
  },
  actions: {
    async GetDashboard(context) {
      context.commit("SET_LOADING_DASHBOARD", true);
      try {
        const result = await axios({
          url: `${apiUrl}/dashboard`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit(
          "SET_TOTAL_HUB_ORIGIN_DASHBOARD",
          result.data.data.totalHubOrigin
        );
        context.commit(
          "SET_TOTAL_RECEIPT_PROCESS_DASHBOARD",
          result.data.data.totalReceiptProcess
        );
        context.commit(
          "SET_TOTAL_HUB_DESTINATION_DASHBOARD",
          result.data.data.totalHubDestination
        );
        context.commit(
          "SET_TOTAL_RECEIPT_DELIVERED_DASHBOARD",
          result.data.data.totalReceiptDelivered
        );
        context.commit(
          "SET_TOTAL_RECEIPT_FAILED_DASHBOARD",
          result.data.data.totalReceiptFailed
        );

        result.data.data.receiptFailed.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit(
          "SET_RECEIPT_FAILED_DASHBOARD",
          result.data.data.receiptFailed
        );

        return true;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_LOADING_DASHBOARD", false);
      }
    },
  },
};

export default dashboard;
