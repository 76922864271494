import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const inHubDestination = {
  state: {
    isLoading: false,
    list_inbound: [],
    reports: [],
  },
  mutations: {
    SET_IS_LOADING_IN_HUB_DESTINATION(state, payload) {
      state.isLoading = payload;
    },
    SET_LIST_INBOUND_IN_HUB_DESTINATION(state, payload) {
      state.list_inbound = payload;
    },
    SET_REPORTS_IN_HUB_DESTINATION(state, payload) {
      state.reports = payload;
    },
  },
  actions: {
    async FetchBeforeFormInHubDestination(context) {
      context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound/list-posted`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_INBOUND_IN_HUB_DESTINATION", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", false);
      }
    },
    async FetchInboundDetailInHubDestination(context, id) {
      context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound/list-posted/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_IN_HUB_DESTINATION", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", false);
      }
    },
    async UpdateStatusInboundInHubDestination(context, payload) {
      context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", true);
      try {
        await axios({
          url: `${apiUrl}/inbound/update-status-inhuborigin/${payload.bag_code}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.dispatch("FetchBeforeFormInHubDestination");
        context.dispatch("FetchInboundDetailInHubDestination", payload.id);
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", false);
      }
    },
    async UpdateStatusTransitInboundInHubDestination(context, code_receipt) {
      context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound/update-status-transit/${code_receipt}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.dispatch("FetchBeforeFormInHubDestination");

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_IN_HUB_DESTINATION", false);
      }
    },
  },
};

export default inHubDestination;
