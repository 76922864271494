import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;

const checkResi = {
  state: {
    isLoading: false,
    result: "",
  },
  mutations: {
    SET_IS_LOADING_CHECK_RESI(state, payload) {
      state.isLoading = payload;
    },
    SET_RESULT_CHECK_RESI(state, payload) {
      state.result = payload;
    },
  },
  actions: {
    async CheckResi(context, resi) {
      context.commit("SET_IS_LOADING_CHECK_RESI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/guest/check-resi/${resi}`,
          method: "GET",
        });

        context.commit("SET_RESULT_CHECK_RESI", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CHECK_RESI", false);
      }
    },
  },
};

export default checkResi;
