import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const customer = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    form: {
      id_business_unit: "",
      id_city: "",
      code: "",
      name: "",
      address: "",
      email: "",
      phone: "",
      is_active: true,
      is_default: false,
    },
    isUpdate: "",
    list_business_unit: [],
    list_city: [],
  },
  mutations: {
    SET_OPTIONS_TABLE_CUSTOMER(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_CUSTOMER(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_CUSTOMER(state, payload) {
      state.reports = payload;
    },
    SET_FORM_CUSTOMER(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_CUSTOMER(state, payload) {
      state.isUpdate = payload;
    },
    SET_LIST_BUSINESS_UNIT_CUSTOMER(state, payload) {
      state.list_business_unit = payload;
    },
    SET_LIST_CITY_CUSTOMER(state, payload) {
      state.list_city = payload;
    },
  },
  actions: {
    async GetAllCustomer(context) {
      context.commit("SET_IS_LOADING_CUSTOMER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/customer`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        // add no to data
        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_CUSTOMER", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CUSTOMER", false);
      }
    },
    async FetchBeforeFormCustomer(context) {
      context.commit("SET_IS_LOADING_CUSTOMER", true);
      try {
        const businessUnit = await axios({
          url: `${apiUrl}/business-unit/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit(
          "SET_LIST_BUSINESS_UNIT_CUSTOMER",
          businessUnit.data.data
        );
        const city = await axios({
          url: `${apiUrl}/city`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_CITY_CUSTOMER", city.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CUSTOMER", false);
      }
    },
    async CreateCustomer(context) {
      context.commit("SET_IS_LOADING_CUSTOMER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/customer`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllCustomer");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CUSTOMER", false);
      }
    },
    async SetFormUpdateCustomer(context, id) {
      context.commit("SET_IS_LOADING_CUSTOMER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/customer/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          id_business_unit: result.data.data.id_business_unit,
          id_city: result.data.data.id_city,
          code: result.data.data.code,
          name: result.data.data.name,
          address: result.data.data.address,
          email: result.data.data.email,
          phone: result.data.data.phone,
          is_active: result.data.data.is_active,
          is_default: result.data.data.is_default,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CUSTOMER", false);
      }
    },
    async UpdateCustomer(context, id) {
      context.commit("SET_IS_LOADING_CUSTOMER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/customer/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllCustomer");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CUSTOMER", false);
      }
    },
    async DeleteCustomer(context, id) {
      context.commit("SET_IS_LOADING_CUSTOMER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/customer/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllCustomer");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CUSTOMER", false);
      }
    },
  },
};

export default customer;
