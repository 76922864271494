import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./modules/app";

import dashboard from "./modules/dashboard";
import checkPrice from "./modules/check-price";
import checkResi from "./modules/check-resi";

// Shipment Out
import receipt from "./modules/shipment-out/receipt";
import requestPickup from "./modules/shipment-out/request-pickup";
import inHubOrigin from "./modules/shipment-out/in-hub-origin";
import bag from "./modules/shipment-out/bag";
import outboundCentral from "./modules/shipment-out/outbound-central";
import outboundSMU from "./modules/shipment-out/outbound-smu";
import outboundManifest from "./modules/shipment-out/outbound-manifest";

// Shipment In
import inbound from "./modules/shipment-in/inbound";
import inHubDestination from "./modules/shipment-in/in-hub-detination";
import sortir from "./modules/shipment-in/sortir";
import delivery from "./modules/shipment-in/delivery";
import delivered from "./modules/shipment-in/delivered";

// Setup
import businessUnit from "./modules/business-unit";
import city from "./modules/city";
import ceisa from "./modules/ceisa";
import rateCity from "./modules/rate-city";
import threeLetterCode from "./modules/three-letter-code";
import commodity from "./modules/commodity";
import customer from "./modules/customer";
import status from "./modules/status";
import specialShipping from "./modules/special-shipping";

// Utility
import numberingPrefix from "./modules/numbering-prefix";
import userManagement from "./modules/user-management";

import document from "./modules/document";

export default new Vuex.Store({
  modules: {
    app,
    dashboard,
    document,
    checkPrice,
    checkResi,
    receipt,
    requestPickup,
    inHubOrigin,
    bag,
    outboundCentral,
    outboundSMU,
    outboundManifest,
    inbound,
    inHubDestination,
    sortir,
    delivery,
    delivered,
    businessUnit,
    city,
    ceisa,
    rateCity,
    threeLetterCode,
    commodity,
    customer,
    status,
    numberingPrefix,
    userManagement,
    specialShipping,
  },
});
