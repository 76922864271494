<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment In" title="Delivered" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <v-form
                  class="row mb-5 align-items-center"
                  ref="initialReport"
                  @submit.prevent="handleSubmit"
                >
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Sortir Code</label>
                    <v-autocomplete
                      placeholder="Sortir Code"
                      item-text="code"
                      item-value="id"
                      :items="list_sortir"
                      @change="handleChange"
                      v-model="id"
                      outlined
                      dense
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Sortir Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Receipt Code</label>
                    <v-text-field
                      placeholder="Receipt Code"
                      outlined
                      dense
                      v-model="receipt_code"
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Receipt Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-btn class="btn bg-navy mr-3" type="submit"> Scan </v-btn>
                    <v-btn class="btn bg-navy" @click="handleFailed">
                      Set To Failed
                    </v-btn>
                  </div>
                </v-form>
                <div class="card shadow-none border rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-end">
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="search"
                      />
                    </div>

                    <v-data-table
                      :headers="headers"
                      :items="reports"
                      :loading="isLoading"
                      :search="search"
                    >
                      <template v-slot:[`item.is_received`]="{ item }">
                        {{ item.is_received ? "Received" : "Not Received" }}
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalForm" persistent max-width="800">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
    <v-dialog v-model="modalFormFailed" persistent max-width="800">
      <FormFailed @handleModalFormFailed="handleModalFormFailed" />
    </v-dialog>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";

export default {
  name: "ShipmentInHubDestinationPage",
  mixins: [ValidationRules],
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
    FormFailed: () => import("./form-failed.vue"),
  },
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Code", value: "receipt.code" },
        { text: "Receiver Name", value: "receipt.receiver_name" },
        { text: "Receiver Phone", value: "receipt.receiver_phone" },
        { text: "Receiver Address", value: "receipt.receiver_address" },
        { text: "Status", value: "is_received" },
        { text: "Receiver Name (Delivered)", value: "received_name" },
        { text: "Receiver Status (Delivered)", value: "received_status" },
      ],
      modalForm: false,
      modalFormFailed: false,
      search: "",
    };
  },
  watch: {
    id(val) {
      if (val == "") {
        this.$store.commit("SET_REPORTS_DELIVERED", []);
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.delivered.isLoading;
    },
    list_sortir() {
      return this.$store.state.delivered.list_sortir;
    },
    reports() {
      return this.$store.state.delivered.reports;
    },
    id: {
      get() {
        return this.$store.state.delivered.form.id;
      },
      set(value) {
        this.$store.commit("SET_FORM_DELIVERED", {
          key: "id",
          value,
        });
      },
    },
    receipt_code: {
      get() {
        return this.$store.state.delivered.form.receipt_code;
      },
      set(value) {
        this.$store.commit("SET_FORM_DELIVERED", {
          key: "receipt_code",
          value,
        });
      },
    },
  },
  methods: {
    handleChange(id) {
      this.$store.dispatch("FetchSortirDetailDelivered", id);
    },
    handleSubmit() {
      //   this.$store.dispatch("UpdateStatusSortirDetail", this.form);
      if (this.$refs.initialReport.validate()) {
        this.handleModalForm();
      }
    },
    handleFailed() {
      this.$refs.initialReport.validate();
      if (this.$refs.initialReport.validate()) {
        this.handleModalFormFailed();
      }
    },
    handleModalForm() {
      this.modalForm = !this.modalForm;
    },
    handleModalFormFailed() {
      this.modalFormFailed = !this.modalFormFailed;
    },
  },
  beforeDestroy() {
    this.$store.commit("SET_REPORTS_DELIVERED", []);
    this.$store.commit("SET_FORM_DELIVERED", {
      key: "id",
      value: "",
    });
  },
  mounted() {
    this.$store.commit("SET_REPORTS_DELIVERED", []);

    this.$store.dispatch("FetchSortirDelivered");
  },
};
</script>
