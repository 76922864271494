import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const status = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 15,
      search: "",
    },
    reports: [],
    form: {
      code: "",
      name: "",
      remark_internal: "",
      remark_external: "",
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_STATUS(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_STATUS(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_STATUS(state, payload) {
      state.reports = payload;
    },
    SET_FORM_STATUS(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_STATUS(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllStatus(context) {
      context.commit("SET_IS_LOADING_STATUS", true);
      try {
        const result = await axios({
          url: `${apiUrl}/status`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORTS_STATUS", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_STATUS", false);
      }
    },
    async SetFormUpdateStatus(context, id) {
      context.commit("SET_IS_LOADING_STATUS", true);
      try {
        const result = await axios({
          url: `${apiUrl}/status/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          code: result.data.data.code,
          name: result.data.data.name,
          remark_internal: result.data.data.remark_internal,
          remark_external: result.data.data.remark_external,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_STATUS", false);
      }
    },
    async UpdateStatus(context, id) {
      context.commit("SET_IS_LOADING_STATUS", true);
      try {
        const result = await axios({
          url: `${apiUrl}/status/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllStatus");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_STATUS", false);
      }
    },
  },
};

export default status;
