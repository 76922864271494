import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";
import moment from "moment";

const form = {
  code: "",
  date: moment().format("DD MMMM YYYY"),
  qty: "",
  id_business_unit_destination: "",
  id_shipping_port: "",
};

const bag = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    list_business_unit: [],
    list_shipping_port: [],
    form: {
      ...form,
    },
    reports: [],
    report: {},
    isUpdate: "",
    list_bag: [],
    list_bag_detail: [],
    select_print_barcode: [],
  },
  mutations: {
    SET_OPTIONS_TABLE_BAG(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_BAG(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_BAG(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_BAG(state, payload) {
      state.report = payload;
    },
    SET_LIST_BUSINESS_UNIT(state, payload) {
      state.list_business_unit = payload;
    },
    SET_LIST_SHIPPING_PORT(state, payload) {
      state.list_shipping_port = payload;
    },
    SET_FORM_BAG(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_BAG(state) {
      state.form = { ...form };
    },
    SET_IS_UPDATE_BAG(state, payload) {
      state.isUpdate = payload;
    },
    SET_LIST_BAG(state, payload) {
      state.list_bag = payload;
    },
    SET_LIST_BAG_DETAIL(state, payload) {
      state.list_bag_detail = payload;
    },
    SET_SELECT_PRINT_BARCODE_BAG(state, payload) {
      state.select_print_barcode = payload;
    },
  },
  actions: {
    async FetchBag(context) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_BAG", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async FetchBagById(context, id) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_BAG", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async FetchBeforeFormBag(context) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const businessUnit = await axios({
          url: `${apiUrl}/business-unit/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        businessUnit.data.data = businessUnit.data.data.filter(
          (item) => item.type === "Hub"
        );
        businessUnit.data.data = businessUnit.data.data.filter(
          (item) => item.id !== context.rootState.app.user.business_unit.id
        );
        context.commit("SET_LIST_BUSINESS_UNIT", businessUnit.data.data);

        const shippingPort = await axios({
          url: `${apiUrl}/shipping-port/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_SHIPPING_PORT", shippingPort.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async CreateBag(context) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchBag");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async SetFormUpdateBag(context, id) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        const data = result.data.data;

        context.state.form = {
          code: data.code,
          date: moment(data.date).format("DD MMMM YYYY"),
          qty: data.qty,
          id_business_unit_destination: data.id_business_unit_destination,
          id_shipping_port: data.id_shipping_port,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async UpdateBag(context, id) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchBag");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async UpdateBagStatus(context, payload) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/status/${payload.code}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            status: payload.status,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchBag");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async UpdateBagStatusMany(context, payload) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/status-many`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            data: payload.map((item) => item.id),
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchBag");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async DeleteBag(context, id) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchBag");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async FetchBagListEntry(context) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_BAG", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async FetchReceiptBagDetail(context, code) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/list/${code}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_LIST_BAG_DETAIL", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async CreateBagDetail(context, payload) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        await axios({
          url: `${apiUrl}/bag/detail`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: payload,
        });

        context.dispatch("FetchReceiptBagDetail", payload.code_bag);

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
    async DeleteReceiptBagDetail(context, payload) {
      context.commit("SET_IS_LOADING_BAG", true);
      try {
        await axios({
          url: `${apiUrl}/bag/detail/${payload.id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.dispatch("FetchReceiptBagDetail", payload.code_bag);
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BAG", false);
      }
    },
  },
};

export default bag;
