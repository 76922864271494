import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";
import moment from "moment";

const outboundCentral = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    list_bagging: [],
    form: {
      code: "",
      date: moment().format("YYYY-MM-DD HH:mm"),
      remarks: "",
      pic_name: "",
      pic_vehicle: "",
      details: [],
    },
    isUpdate: false,
    formUploadBillingTPS: {
      id: "",
      code: "",
      total_billing: "",
      pathbillingtps: "",
      preview: "",
    },
    formUploadBTBTPS: {
      id: "",
      code: "",
      total_weight: "",
      pathbtbtps: "",
      preview: "",
    },
  },
  mutations: {
    SET_OPTIONS_TABLE_OUTBOUND_CENTRAL(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_OUTBOUND_CENTRAL(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_OUTBOUND_CENTRAL(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_OUTBOUND_CENTRAL(state, payload) {
      state.report = payload;
    },
    SET_LIST_BAGGING(state, payload) {
      state.list_bagging = payload;
    },
    SET_FORM_OUTBOUND_CENTRAL(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_OUTBOUND_CENTRAL(state) {
      state.form = {
        code: "",
        date: moment().format("YYYY-MM-DD HH:mm"),
        remarks: "",
        pic_name: "",
        pic_vehicle: "",
        details: [],
      };
    },
    SET_IS_UPDATE_OUTBOUND_CENTRAL(state, payload) {
      state.isUpdate = payload;
    },
    SET_FORM_UPLOAD_BILLING_TPS(state, payload) {
      state.formUploadBillingTPS[payload.key] = payload.value;
    },
    SET_FORM_UPLOAD_BTB_TPS(state, payload) {
      state.formUploadBTBTPS[payload.key] = payload.value;
    },
  },
  actions: {
    async FetchOutboundCentral(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_OUTBOUND_CENTRAL", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async FetchOutboundCentralById(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_OUTBOUND_CENTRAL", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async SetBeforeFormOutbound(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/bag/list-posted`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_BAGGING", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async CreateOutboundCentral(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundCentral");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async UpdateOutboundCentralEntry(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/status-entry/${id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundCentral");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async UpdateOutboundCentralPosted(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/status-posted/${id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundCentral");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async SetFormOutboundCentral(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_FORM_OUTBOUND_CENTRAL", {
          key: "code",
          value: result.data.data.code,
        });
        context.commit("SET_FORM_OUTBOUND_CENTRAL", {
          key: "remarks",
          value: result.data.data.remarks,
        });

        let details = [];
        for (const iterator of result.data.data.details) {
          details.push(iterator.bag);
        }

        context.state.list_bagging.push(...details);

        context.commit("SET_FORM_OUTBOUND_CENTRAL", {
          key: "details",
          value: details,
        });
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async UpdateOutboundCentral(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundCentral");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async SetFormUploadBillingTPSOutboundCentral(context, payload) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/${payload.id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.formUploadBillingTPS = {
          id: payload.id,
          code: payload.code,
          total_billing: result.data.data.total_billing,
          preview: result.data.data.pathbillingtps,
          pathbillingtps: null,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async UploadBillingTPSOutboundCentral(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);

      const form = context.state.formUploadBillingTPS;

      const payload = new FormData();
      payload.append("pathbillingtps", form.pathbillingtps);
      payload.append("total_billing", form.total_billing);

      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/upload-billing-tps/${form.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: payload,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async SetFormUploadBTBTPSOutboundCentral(context, payload) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/${payload.id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.formUploadBTBTPS = {
          id: payload.id,
          code: payload.code,
          total_weight: result.data.data.total_weight,
          preview: result.data.data.pathbtbtps,
          pathbtbtps: null,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async UploadBTBTPSOutboundCentral(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);

      const form = context.state.formUploadBTBTPS;

      const payload = new FormData();
      payload.append("pathbtbtps", form.pathbtbtps);
      payload.append("total_weight", form.total_weight);

      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/upload-btb-tps/${form.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: payload,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
    async UpdateStatusCustomClearanceOutboundCentral(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", true);

      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/status-custom-clearance/${id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_CENTRAL", false);
      }
    },
  },
};

export default outboundCentral;
