<template>
  <div class="content-wrapper">
    <ContentHeader header="Utility" title="Numbering Prefix" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-end">
                  <v-text-field
                    class="input-search"
                    label="Cari"
                    prepend-inner-icon="mdi-magnify"
                    v-model="optionsTable.search"
                    outlined
                    dense
                  />
                </div>
                <v-data-table
                  :headers="headers"
                  :items="reports"
                  :loading="isLoading"
                  :options.sync="optionsTable"
                  :search="optionsTable.search"
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      icon
                      color="white"
                      class="bg-warning"
                      @click="handleUpdate(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalUpdate" persistent max-width="700">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "UtilityNumberingPrefixPage",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
  },
  data() {
    return {
      headers: [
        { text: "Desc", value: "desc" },
        { text: "Format", value: "format" },
        { text: "Prefix", value: "prefix" },
        { text: "Action", value: "action", sortable: false, align: "right" },
      ],
      modalUpdate: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.numberingPrefix.isLoading;
    },
    reports() {
      return this.$store.state.numberingPrefix.reports;
    },
    optionsTable: {
      get() {
        return this.$store.state.numberingPrefix.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_NUMBERING_PREFIX", value);
      },
    },
  },
  methods: {
    handleModalForm() {
      this.modalUpdate = !this.modalUpdate;
    },
    handleUpdate(item) {
      this.$store.dispatch("SetFormUpdateNumberingPrefix", item.id);
      this.handleModalForm();
    },
  },
  mounted() {
    this.$store.dispatch("GetAllNumberingPrefix");
  },
};
</script>
