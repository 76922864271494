<template>
  <div class="content-wrapper">
    <ContentHeader
      header="Shipment Out / Bagging"
      title="Scan Receipt to Bag"
    />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <v-form
                  class="row mb-5 align-items-end"
                  ref="initialReport"
                  @submit.prevent="handleSubmit"
                >
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Bag Code</label>
                    <v-combobox
                      placeholder="Do Code"
                      :items="list_bag"
                      item-text="code"
                      item-value="code"
                      @change="handleChange"
                      outlined
                      dense
                      hide-details="true"
                      v-model="form.code_bag"
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Bag Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Receipt Code</label>
                    <v-text-field
                      placeholder="Receipt Code"
                      outlined
                      dense
                      class="barcode-input"
                      hide-details="true"
                      v-model="form.code"
                      ref="input_receipt_code"
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Receipt Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-btn
                      class="btn bg-navy mr-3"
                      type="submit"
                      :loading="isLoading"
                      >Scan</v-btn
                    >
                    <v-btn class="btn bg-navy" @click="setStatusBagPosted()">
                      Post Bag
                    </v-btn>
                  </div>
                </v-form>
                <div class="card shadow-none border rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div>
                        <v-btn
                          class="btn bg-navy ml-md-3 mb-3 mb-md-0"
                          @click="handleDeleteMany(true)"
                          v-if="select_table.length > 0"
                        >
                          Delete Selected
                        </v-btn>
                      </div>

                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="search"
                      />
                    </div>

                    <v-data-table
                      :headers="headers"
                      :items="list_bag_detail"
                      :search="search"
                      :loading="isLoading"
                      v-model="select_table"
                      show-select
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        {{
                          item.receipt.receipt_date &&
                          moment(item.receipt.receipt_date).format(
                            "DD MMMM YYYY | hh:mm"
                          )
                        }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn
                          icon
                          color="white"
                          class="bg-danger"
                          @click="handleDelete(item.id)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import Swal from "sweetalert2";
import moment from "moment";
import Toastify from "toastify-js";

export default {
  name: "ScanReceiptInBag",
  mixins: [ValidationRules],
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
  },
  data() {
    return {
      search: "",
      moment,
      headers: [
        { text: "No", value: "no" },
        { text: "Date", value: "date" },
        { text: "Receipt", value: "receipt.code" },
        { text: "Origin", value: "receipt.rate_city.code" },
        { text: "3LC", value: "receipt.rate_city.code_reg" },
        { text: "Weight", value: "receipt.weight" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      form: {
        code_bag: "",
        code: "",
      },
      select_table: [],
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.bag.isLoading;
    },
    list_bag() {
      return this.$store.state.bag.list_bag;
    },
    list_bag_detail() {
      return this.$store.state.bag.list_bag_detail;
    },
  },
  methods: {
    handleChange(item) {
      if (typeof item === "object") {
        this.$store.dispatch("FetchReceiptBagDetail", item.code);
      } else {
        this.form.code_bag = this.form.code_bag.toUpperCase();
        this.$store.dispatch("FetchReceiptBagDetail", item);
      }

      this.$refs.input_receipt_code.focus();
    },
    handleSubmit() {
      this.$refs.initialReport.validate();

      let data = {
        code_bag: this.form.code_bag,
        code: this.form.code,
      };

      if (typeof this.form.code_bag === "object") {
        data.code_bag = this.form.code_bag.code;
      }

      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("CreateBagDetail", data).then((res) => {
          if (res) {
            this.form.code = "";

            Toastify({
              text: "Success",
              className: "success",
              gravity: "bottom",
            }).showToast();
          }
        });
      }
    },
    handleDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DeleteReceiptBagDetail", {
            id: id,
            code_bag: this.form.code_bag.code,
          });
        }
      });
    },
    handleDeleteMany() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          for (const iterator of this.select_table) {
            this.$store.dispatch("DeleteReceiptBagDetail", {
              id: iterator.id,
              code_bag: this.form.code_bag,
            });
          }

          this.select_table = [];
        }
      });
    },
    setStatusBagPosted() {
      if (this.form.code_bag == "") {
        return;
      }
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, post it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let code = this.form.code_bag;
          if (typeof this.form.code_bag === "object") {
            code = this.form.code_bag.code;
          }

          this.$store
            .dispatch("UpdateBagStatus", {
              code: code,
              status: "POSTED",
            })
            .then((res) => {
              if (res) {
                this.form.code_bag = "";
                this.form.code = "";
                this.$store.dispatch("FetchBagListEntry");
              }
            });
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("FetchBagListEntry");
  },
};
</script>
