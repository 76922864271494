import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const form = {
  code: "",
  smu_name: "",
  plane_number: "",
  take_off_time: "",
  plane_destination: "",
  remarks: "",
  details: "",
};

const outboundSMU = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    list_bagging: [],
    form: {
      ...form,
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_OUTBOUND_SMU(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_OUTBOUND_SMU(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_OUTBOUND_SMU(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_OUTBOUND_SMU(state, payload) {
      state.report = payload;
    },
    SET_LIST_BAGGING_OUTBOUND_SMU(state, payload) {
      state.list_bagging = payload;
    },
    SET_FORM_OUTBOUND_SMU(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_OUTBOUND_SMU(state) {
      state.form = { ...form };
    },
    SET_IS_UPDATE_OUTBOUND_SMU(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async FetchOutboundSMU(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_OUTBOUND_SMU", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async FetchOutboundSMUById(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_OUTBOUND_SMU", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async FetchBeforeFormOutboundSMU(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const bagging = await axios({
          url: `${apiUrl}/bag/list-posted-smu`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_BAGGING_OUTBOUND_SMU", bagging.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async CreateOutboundSMU(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundSMU");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async UpdateStatusPostedOutboundSMU(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu/status-posted/${id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundSMU");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async UpdateStatusEntryOutboundSMU(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu/status-entry/${id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundSMU");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async SetFormUpdateOutboundSMU(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        let details = [];
        for (const iterator of result.data.data.details) {
          details.push(iterator.bag);
        }

        context.state.form = {
          code: result.data.data.code,
          smu_name: result.data.data.smu_name,
          plane_number: result.data.data.plane_number,
          take_off_time: result.data.data.take_off_time,
          plane_destination: result.data.data.plane_destination,
          remarks: result.data.data.remarks,
          details: details,
        };

        context.state.list_bagging.push(...details);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
    async UpdateOutboundSMU(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_SMU", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchOutboundSMU");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_SMU", false);
      }
    },
  },
};

export default outboundSMU;
