<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment In" title="Inbound" />

    <section class="content">
      <div class="container-fluid">
        <div class="mb-3 d-flex gap-10">
          <HandleScanBagInbound v-if="isHub" />
          <HandleScanReceiptByCustomer v-if="isHub" />
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <div class="row mb-5 align-items-center">
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-autocomplete
                      label="Business Unit"
                      :items="business_unit_list"
                      v-model="business_unit_active"
                      outlined
                      dense
                      hide-details="true"
                    />
                  </div>
                  <div class="col-md-7 col-lg-6 mb-3 mb-sm-0">
                    <DateRangePicker />
                  </div>
                </div>

                <br />

                <ul class="pl-0 nav nav-tabs">
                  <li class="nav-item" v-for="(item, i) in tab_list" :key="i">
                    <a
                      :class="`nav-link ${
                        item.title === tab_active && 'active fw-semibold'
                      }`"
                      @click="() => (tab_active = item.title)"
                    >
                      {{ item.title }}
                      <span :class="`badge ${item.color} badge-pill`">
                        {{ item.value }}
                      </span>
                    </a>
                  </li>
                </ul>

                <div class="card shadow-none border border-top-0 rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-end">
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="optionsTable.search"
                      />
                    </div>
                    <v-data-table
                      :headers="headers"
                      :items="setReportsByTab()"
                      :loading="isLoading"
                      :options.sync="optionsTable"
                      :search="optionsTable.search"
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        {{ moment(item.date).format("DD MMMM YYYY | HH:mm") }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="btn btn-outline-primary py-4"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="fw-light mr-1">Action</span>
                              <i class="fa-solid fa-chevron-down small"></i>
                            </v-btn>
                          </template>
                          <v-list min-width="150">
                            <v-list-item
                              @click="handleModalDetail(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fa-regular fa-eye small mr-2"></i>
                                <span class="small">Detail</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusPosted(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set to Posted</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusEntry(item.id)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set to Entry</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleUpdate(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Edit</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalDetail" persistent max-width="1000">
      <Detail @handleModalDetail="handleModalDetail" />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ShipmentInInboundPage",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    HandleScanBagInbound: () => import("./handle-scan-bag-inbound.vue"),
    HandleScanReceiptByCustomer: () =>
      import("./handle-scan-receipt-by-customer.vue"),
    Detail: () => import("./detail.vue"),
    DateRangePicker: () =>
      import("@/components/molecules/date-range-picker.vue"),
  },
  data() {
    return {
      moment,
      business_unit_list: ["All"],
      business_unit_active: "All",
      tab_active: "Entry",
      tab_list: [
        { title: "Entry", value: 0, color: "badge-info" },
        { title: "Posted", value: 0, color: "badge-success" },
        { title: "Completed", value: 0, color: "badge-warning" },
        { title: "All", value: 0, color: "badge-dark" },
      ],
      headers: [
        // { text: "No", value: "no" },
        { text: "Manifest Code", value: "code" },
        { text: "Date", value: "date" },
        { text: "Status", value: "status_inbound" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      modalDetail: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.inbound.isLoading;
    },
    reports() {
      return this.$store.state.inbound.reports;
    },
    isHub() {
      return this.$store.state.app.user.business_unit.type === "Hub"
        ? true
        : false;
    },
    optionsTable: {
      get() {
        return this.$store.state.inbound.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_INBOUND", value);
      },
    },
    start_date() {
      return this.$store.state.app.filter_range.start_date;
    },
    end_date() {
      return this.$store.state.app.filter_range.end_date;
    },
  },
  watch: {
    reports() {
      this.setTabFilter();
    },
    async start_date() {
      await this.$store.dispatch("GetAllInbound");
      this.setTabFilter();
    },
    async end_date() {
      await this.$store.dispatch("GetAllInbound");
      this.setTabFilter();
    },
  },
  methods: {
    setTabFilter() {
      this.tab_list.forEach((item) => {
        item.value = this.reports.filter(
          // set lower case to make it case insensitive
          (x) => x.status_inbound.toLowerCase() === item.title.toLowerCase()
        ).length;
      });

      this.tab_list[3].value = this.reports.length;

      for (const iterator of this.reports) {
        if (!this.business_unit_list.includes(iterator.business_unit.code)) {
          this.business_unit_list.push(iterator.business_unit.code);
        }
      }
    },
    setReportsByTab() {
      let result = [];
      if (this.tab_active === "All") {
        result = this.reports;
      } else {
        result = this.reports.filter(
          (x) =>
            x.status_inbound.toLowerCase() === this.tab_active.toLowerCase()
        );
      }

      return result.filter(
        (x) =>
          x.business_unit.code === this.business_unit_active ||
          this.business_unit_active === "All"
      );
    },
    handleModalDetail(value, id) {
      if (value) this.$store.dispatch("GetInboundById", id);
      this.modalDetail = value;
    },
  },
  async mounted() {
    await this.$store.dispatch("GetAllInbound");

    this.setTabFilter();
  },
};
</script>
