var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('ContentHeader',{attrs:{"header":"Shipment Out / In Hub Origin","title":"Receive at Warehouse"}}),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card pt-5"},[_c('div',{staticClass:"card-body"},[_c('v-form',{ref:"initialReport",staticClass:"row mb-5 align-items-center",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('label',[_vm._v("Do Code")]),_c('v-autocomplete',{attrs:{"placeholder":"Do Code","items":_vm.list_inHubOrigin,"item-text":"code","item-value":"id","outlined":"","dense":"","rules":[
                      (value) => {
                        return _vm.genericRequiredRule(value, 'Do Code');
                      },
                    ]},on:{"change":_vm.handleChangeDoCode},model:{value:(_vm.form.idInHubOrigin),callback:function ($$v) {_vm.$set(_vm.form, "idInHubOrigin", $$v)},expression:"form.idInHubOrigin"}})],1),_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('label',[_vm._v("Receipt Code")]),_c('v-text-field',{staticClass:"barcode-input",attrs:{"placeholder":"Receipt Code","outlined":"","dense":"","rules":[
                      (value) => {
                        return _vm.genericRequiredRule(value, 'Receipt Code');
                      },
                    ]},model:{value:(_vm.form.idReceipt),callback:function ($$v) {_vm.$set(_vm.form, "idReceipt", $$v)},expression:"form.idReceipt"}})],1),_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('v-btn',{staticClass:"btn bg-navy",attrs:{"type":"submit","loading":_vm.isLoading}},[_vm._v(" Scan ")])],1)]),_c('div',{staticClass:"card shadow-none border rounded-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-text-field',{staticClass:"input-search",attrs:{"label":"Cari","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list_receipt_receiveAtWarehouse,"search":_vm.search,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:`item.received_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.received_date && _vm.moment(item.received_date).format( "DD MMMM YYYY | hh:mm" ))+" ")]}},{key:`item.remarks`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.is_received ? "WAREHOUSE ORIGIN" : "")+" ")]}}],null,true)})],1)])],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }