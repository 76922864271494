import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const document = {
  state: {
    isReady: {
      barcodeReceipt: false,
      barcodeCN32: false,
      barcodeBag: false,
      inHubOrigin: false,
      doCentral: false,
      doSMU: false,
      doManifest: false,
      doSortir: false,
      doCourier: false,
    },
    docsBarcodeReceipt: [],
    docsBarcodeCN32: "",
    docsBarcodeBag: "",
    docsInHubOrigin: "",
    docsDoCentral: "",
    docsDoSMU: "",
    docsDoManifest: "",
    docsDoSortir: "",
    docsDoCourier: "",
  },
  mutations: {
    SET_IS_READY_DOCUMENT(state, payload) {
      state.isReady[payload.key] = payload.value;
    },
    SET_DOCS_BARCODE_RECEIPT(state, payload) {
      state.docsBarcodeReceipt = payload;
    },
    SET_DOCS_BARCODE_CN32(state, payload) {
      state.docsBarcodeCN32 = payload;
    },
    SET_DOCS_BARCODE_BAG(state, payload) {
      state.docsBarcodeBag = payload;
    },
    SET_DOCS_IN_HUB_ORIGIN(state, payload) {
      state.docsInHubOrigin = payload;
    },
    SET_DOCS_DO_CENTRAL(state, payload) {
      state.docsDoCentral = payload;
    },
    SET_DOCS_DO_SMU(state, payload) {
      state.docsDoSMU = payload;
    },
    SET_DOCS_DO_MANIFEST(state, payload) {
      state.docsDoManifest = payload;
    },
    SET_DOCS_DO_SORTIR(state, payload) {
      state.docsDoSortir = payload;
    },
    SET_DOCS_DO_COURIER(state, payload) {
      state.docsDoCourier = payload;
    },
  },
  actions: {
    async FetchDocsBarcodeReceiptMany(context, payload) {
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/print-barcode`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: payload,
        });

        context.commit("SET_IS_READY_DOCUMENT", {
          key: "barcodeReceipt",
          value: true,
        });

        context.commit("SET_DOCS_BARCODE_RECEIPT", result.data.data);

        if (result.data.data.length === 0) {
          Swal.fire({
            title: "Success",
            text: "Data barcode dalam range tanggal yang dipilih kosong",
            icon: "info",
            confirmButtonText: "OK",
          });

          return false;
        }

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsBarcodeReceiptSelected(context, payload) {
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/print-barcode/selected`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            selected: payload.map((item) => item.id),
          },
        });

        context.commit("SET_IS_READY_DOCUMENT", {
          key: "barcodeReceipt",
          value: true,
        });

        context.commit("SET_DOCS_BARCODE_RECEIPT", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsBarcodeReceiptById(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_IS_READY_DOCUMENT", {
          key: "barcodeReceipt",
          value: true,
        });

        let docs = [];
        docs.push(result.data.data);
        context.commit("SET_DOCS_BARCODE_RECEIPT", docs);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsBarcodeCN32(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_IS_READY_DOCUMENT", {
          key: "barcodeCN32",
          value: true,
        });

        context.commit("SET_DOCS_BARCODE_CN32", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsBarcodeBagById(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/bag/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "barcodeBag",
          value: true,
        });
        let docs = [];
        docs.push(result.data.data);
        context.commit("SET_DOCS_BARCODE_BAG", docs);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsBarcodeBagSelected(context, payload) {
      try {
        const result = await axios({
          url: `${apiUrl}/bag/array-id`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            data: payload.map((item) => item.id),
          },
        });

        context.commit("SET_IS_READY_DOCUMENT", {
          key: "barcodeBag",
          value: true,
        });

        context.commit("SET_DOCS_BARCODE_BAG", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsDoInHubOrigin(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/in-hub-origin/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "inHubOrigin",
          value: true,
        });
        context.commit("SET_DOCS_IN_HUB_ORIGIN", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsDoCentral(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/central/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "doCentral",
          value: true,
        });
        context.commit("SET_DOCS_DO_CENTRAL", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsDoSMU(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/smu/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "doSMU",
          value: true,
        });
        context.commit("SET_DOCS_DO_SMU", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsDoManifest(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "doManifest",
          value: true,
        });
        context.commit("SET_DOCS_DO_MANIFEST", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsDoSortir(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/sortir/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "doSortir",
          value: true,
        });
        context.commit("SET_DOCS_DO_SORTIR", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
    async FetchDocsDoCourier(context, id) {
      try {
        const result = await axios({
          url: `${apiUrl}/delivery/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_IS_READY_DOCUMENT", {
          key: "doCourier",
          value: true,
        });
        context.commit("SET_DOCS_DO_COURIER", result.data.data);

        return true;
      } catch (error) {
        catchUnauthorized(error);
      }
    },
  },
};

export default document;
