import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const businessUnit = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    list_city: [],
    form: {
      code: "",
      type: "",
      desc: "",
      phone: "",
      fax: "",
      email: "",
      country: "",
      id_city: "",
      post_code: "",
      address: "",
      remarks: "",
      is_active: false,
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_BUSINESS_UNIT(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_BUSINESS_UNIT(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_BUSINESS_UNIT(state, payload) {
      state.reports = payload;
    },
    SET_LIST_CITY_BUSINESS_UNIT(state, payload) {
      state.list_city = payload;
    },
    SET_FORM_BUSINESS_UNIT(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_BUSINESS_UNIT(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllBusinessUnit(context) {
      context.commit("SET_IS_LOADING_BUSINESS_UNIT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/business-unit`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        // add no to data
        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_BUSINESS_UNIT", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BUSINESS_UNIT", false);
      }
    },
    async FetchBeforeFormBusinessUnit(context) {
      context.commit("SET_IS_LOADING_BUSINESS_UNIT", true);
      try {
        const resultCity = await axios({
          url: `${apiUrl}/city`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_CITY_BUSINESS_UNIT", resultCity.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BUSINESS_UNIT", false);
      }
    },
    async CreateBusinessUnit(context) {
      context.commit("SET_IS_LOADING_BUSINESS_UNIT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/business-unit`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllBusinessUnit");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BUSINESS_UNIT", false);
      }
    },
    async SetFormUpdateBusinessUnit(context, id) {
      context.commit("SET_IS_LOADING_BUSINESS_UNIT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/business-unit/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          code: result.data.data.code,
          type: result.data.data.type,
          desc: result.data.data.desc,
          phone: result.data.data.phone,
          fax: result.data.data.fax,
          email: result.data.data.email,
          country: result.data.data.country,
          id_city: result.data.data.id_city,
          post_code: result.data.data.post_code,
          address: result.data.data.address,
          remarks: result.data.data.remarks,
          is_active: result.data.data.is_active,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_BUSINESS_UNIT", false);
      }
    },
    async UpdateBusinessUnit(context, id) {
      context.commit("SET_IS_LOADING_BUSINESS_UNIT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/business-unit/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllBusinessUnit");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BUSINESS_UNIT", false);
      }
    },
    async DeleteBusinessUnit(context, id) {
      context.commit("SET_IS_LOADING_BUSINESS_UNIT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/business-unit/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllBusinessUnit");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_BUSINESS_UNIT", false);
      }
    },
  },
};

export default businessUnit;
