import catchUnauthorized from "@/utils/catch-unauthorized";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";
import moment from "moment";

const form_print = {
  id_business_unit: 0,
  id_customer: 0,
  start_date: moment().format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};

const receipt = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},

    // FORM
    list_from_city: [],
    list_to_city: [],
    list_sender_status: [
      "E-COMMERCE",
      "PRODUK LOKAL/BATAM",
      "EKS LUAR BATAM/TRANSIT",
      "SALAH KIRIM/RETURN",
      "PERSONAL EFFECT",
    ],
    list_commodity: [],
    list_special_shipping: ["Walk-In", "Retail", "BI"],
    list_customer: [],
    list_business_unit: [],
    form: {
      id_city_sender: "",
      id_city_destination: "",

      sender_name: "",
      sender_phone: "",
      sender_address: "",
      sender_status: "",

      receiver_name: "",
      receiver_phone: "",
      receiver_address: "",
      receiver_npwp: "",

      length: "",
      width: "",
      height: "",
      volume: "", // length * width * height / tbm_expedition_type.measurement (6000)

      qty: "",
      weight: "",
      id_commodity: "",
      price_item: "",
      piece_per_pack: "",
      insurance: "",
      special_shipping: "",
      amount_bi: "", // jika special shipping = bi

      is_wood_packing: false,
      amount_others: "", // jika is_wood_packing true

      id_customer: "",

      is_tax: false,
      total_amount: "",
      is_fictitious: false,

      id_rate_city: "",
      shipping_amount: "",
      tax_bm: "",
      tax_ppn: "",
      tax_pph: "",
      tax_shipping: "",
      amount_tax_bm: "",
      amount_tax_ppn: "",
      amount_tax_pph: "",
      total_tax: "",
    },
    form_import: [],
    isUpdate: "",
    form_print: {
      ...form_print,
    },
    form_select_print: [],
  },
  mutations: {
    SET_IS_LOADING_RECEIPT(state, payload) {
      state.isLoading = payload;
    },
    SET_OPTIONS_TABLE_RECEIPT(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_REPORTS_RECEIPT(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_RECEIPT(state, payload) {
      state.report = payload;
    },
    SET_LIST_FROM_CITY_RECEIPT(state, payload) {
      state.list_from_city = payload;
    },
    SET_LIST_TO_CITY_RECEIPT(state, payload) {
      state.list_to_city = payload;
    },
    SET_LIST_COMMODITY_RECEIPT(state, payload) {
      state.list_commodity = payload;
    },
    SET_LIST_CUSTOMER_RECEIPT(state, payload) {
      state.list_customer = payload;
    },
    SET_LIST_BUSINESS_UNIT_RECEIPT(state, payload) {
      state.list_business_unit = payload;
    },
    SET_FORM_RECEIPT(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_FORM_IMPORT_RECEIPT(state, payload) {
      state.form_import = payload;
    },
    SET_IS_UPDATE_RECEIPT(state, payload) {
      state.isUpdate = payload;
    },
    SET_FORM_PRINT_RECEIPT(state, payload) {
      state.form_print[payload.key] = payload.value;
    },
    RESET_FORM_PRINT_RECEIPT(state) {
      state.form_print = {
        ...form_print,
      };
    },
    SET_FORM_SELECT_PRINT_RECEIPT(state, payload) {
      state.form_select_print = payload;
    },
  },
  actions: {
    async FetchReceipt(context) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORTS_RECEIPT", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async FetchReceiptById(context, id) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_RECEIPT", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    // ============================================================================
    //                              Get list city
    // ============================================================================
    async FetchBeforePrintReceipt(context) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const businessUnit = await axios({
          url: `${apiUrl}/business-unit/list?type=Agent`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        businessUnit.data.data.unshift({
          id: 0,
          code: "All Business Unit",
        });

        context.commit(
          "SET_LIST_BUSINESS_UNIT_RECEIPT",
          businessUnit.data.data
        );

        const customer = await axios({
          url: `${apiUrl}/customer/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        customer.data.data.unshift({
          id: 0,
          name: "All Business Partner",
        });

        context.commit("SET_LIST_CUSTOMER_RECEIPT", customer.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async FetchBeforeFormReceipt(context) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const fromCity = await axios({
          url: `${apiUrl}/check-price/from-city`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_FROM_CITY_RECEIPT", fromCity.data.data);

        const commodity = await axios({
          url: `${apiUrl}/commodity/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_COMMODITY_RECEIPT", commodity.data.data);

        const customer = await axios({
          url: `${apiUrl}/customer/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_CUSTOMER_RECEIPT", customer.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async GetTotalAmountReceipt(context) {
      const form = context.state.form;
      if (form.id_city_sender == "") return;
      if (form.id_city_destination == "") return;
      if (form.volume == "") return;
      if (form.qty == "") return;
      if (form.weight == "") return;
      if (form.id_commodity == "") return;

      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/calculate-total`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        const data = result.data.data;
        context.state.form.id_rate_city = data.id_rate_city;
        context.state.form.price_item = data.price_item;
        context.state.form.shipping_amount = data.shipping_amount;
        context.state.form.tax_bm = data.tax_bm;
        context.state.form.tax_ppn = data.tax_ppn;
        context.state.form.tax_pph = data.tax_pph;
        context.state.form.tax_shipping = data.tax_shipping;
        context.state.form.amount_tax_bm = data.amount_tax_bm;
        context.state.form.amount_tax_ppn = data.amount_tax_ppn;
        context.state.form.amount_tax_pph = data.amount_tax_pph;
        context.state.form.total_tax = data.total_tax;
        context.state.form.total_amount = data.total_amount;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async CreateReceipt(context) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        context.dispatch("FetchReceipt");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async ImportReceipt(context) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/import-receipt`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            data: context.state.form_import,
          },
        });

        context.dispatch("FetchReceipt");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async SetFormUpdateReceipt(context, id) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        const data = result.data.data;

        context.state.form = {
          id_city_sender: data.rate_city.from_city_id,
          id_city_destination: data.rate_city.id,

          sender_name: data.sender_name,
          sender_phone: data.sender_phone,
          sender_address: data.sender_address,
          sender_status: data.sender_status,

          receiver_name: data.receiver_name,
          receiver_phone: data.receiver_phone,
          receiver_address: data.receiver_address,
          receiver_npwp: data.receiver_npwp,

          length: data.length,
          width: data.width,
          height: data.height,
          volume: data.volume,

          qty: data.qty,
          weight: data.weight,
          id_commodity: data.commodity.id,
          price_item: data.price_item,
          piece_per_pack: data.piece_per_pack,
          insurance: data.insurance,
          special_shipping: data.special_shipping,
          amount_bi: data.amount_bi,

          is_wood_packing: data.is_wood_packing,
          amount_others: data.amount_others,

          id_customer: data.id_customer,

          is_tax: data.is_tax,
          total_amount: data.total_amount,
          is_fictitious: data.is_fictitious,

          id_rate_city: data.id_rate_city,
          shipping_amount: data.shipping_amount,
          tax_bm: data.tax_bm,
          tax_ppn: data.tax_ppn,
          tax_pph: data.tax_pph,
          tax_shipping: data.tax_shipping,
          amount_tax_bm: data.amount_tax_bm,
          amount_tax_ppn: data.amount_tax_ppn,
          amount_tax_pph: data.amount_tax_pph,
          total_tax: data.total_tax,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async UpdateReceipt(context, id) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        context.dispatch("FetchReceipt");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async ExportTransactionPerDay(context, range) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/export-transaction-per-day`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: range,
        });

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async ExportDataToExcel(context, range) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/export-data-to-excel`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: range,
        });

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async ExportReceiptForCeisa(context, range) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/ceisa?start_date=${range.start_date}&end_date=${range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async ExportReceiptForCeisaByIdOutboundCentral(context, id) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/ceisa-by-outbound-central/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async ExportReceiptByIdOutboundManifest(context, id) {
      context.commit("SET_IS_LOADING_RECEIPT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/ceisa-by-outbound-manifest/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
    async CancelReceipt(context, receiptId) {
      context.commit("SET_IS_LOADING_RECEIPT", true);

      try {
        const result = await axios({
          url: `${apiUrl}/receipt/${receiptId}/cancel`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        await context.dispatch("FetchReceipt");

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_RECEIPT", false);
      }
    },
  },
};

export default receipt;
