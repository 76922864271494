import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const specialShipping = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    form: {
      id: "",
      amount_tax_bi: "",
      amount_tax_retail: "",
    },
  },
  mutations: {
    SET_OPTIONS_TABLE_SPECIAL_SHIPPING(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_SPECIAL_SHIPPING(state, payload) {
      state.isLoading = payload;
    },
    SET_FORM_SPECIAL_SHIPPING(state, payload) {
      state.form[payload.key] = payload.value;
    },
  },
  actions: {
    async GetSpecialShipping(context) {
      context.commit("SET_IS_LOADING_SPECIAL_SHIPPING", true);
      try {
        const result = await axios({
          url: `${apiUrl}/special-shipping`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          id: result.data.data.id,
          amount_tax_bi: result.data.data.amount_tax_bi,
          amount_tax_retail: result.data.data.amount_tax_retail,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_SPECIAL_SHIPPING", false);
      }
    },
    async UpdateSpecialShipping(context) {
      context.commit("SET_IS_LOADING_SPECIAL_SHIPPING", true);
      try {
        const result = await axios({
          url: `${apiUrl}/special-shipping`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetSpecialShipping");
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_SPECIAL_SHIPPING", false);
      }
    },
  },
};

export default specialShipping;
