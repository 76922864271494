import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const userManagement = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    list_business_unit: [],
    form: {
      is_active: true,
      id_business_unit: "",
      name: "",
      email: "",
      username: "",
      password: "",
      address: "",
      no_hp: "",
    },
    isUpdate: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_USER_MANAGEMENT(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_USER_MANAGEMENT(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_USER_MANAGEMENT(state, payload) {
      state.reports = payload;
    },
    SET_LIST_BUSINESS_UNIT_USER_MANAGEMENT(state, payload) {
      state.list_business_unit = payload;
    },
    SET_FORM_USER_MANAGEMENT(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_USER_MANAGEMENT(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllUserManagement(context) {
      context.commit("SET_IS_LOADING_USER_MANAGEMENT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/user`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        // add no to data
        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_USER_MANAGEMENT", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_USER_MANAGEMENT", false);
      }
    },
    async FetchBeforeFormUserManagement(context) {
      context.commit("SET_IS_LOADING_USER_MANAGEMENT", true);
      try {
        const businessUnit = await axios({
          url: `${apiUrl}/business-unit/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit(
          "SET_LIST_BUSINESS_UNIT_USER_MANAGEMENT",
          businessUnit.data.data
        );
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_USER_MANAGEMENT", false);
      }
    },
    async CreateUserManagement(context) {
      context.commit("SET_IS_LOADING_USER_MANAGEMENT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/register`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
          showConfirmButton: false,
        });

        context.dispatch("GetAllUserManagement");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        return false;
      } finally {
        context.commit("SET_IS_LOADING_USER_MANAGEMENT", false);
      }
    },
    async SetFormUpdateUserManagement(context, id) {
      context.commit("SET_IS_LOADING_USER_MANAGEMENT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/user/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          is_active: result.data.data.is_active,
          id_business_unit: result.data.data.id_business_unit,
          name: result.data.data.name,
          email: result.data.data.email,
          no_hp: result.data.data.no_hp,
          address: result.data.data.address,
          username: result.data.data.username,
          password: null,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_USER_MANAGEMENT", false);
      }
    },
    async UpdateUserManagement(context, id) {
      context.commit("SET_IS_LOADING_USER_MANAGEMENT", true);
      try {
        const payload = context.state.form;
        if (payload.password === null) delete payload.password;

        const result = await axios({
          url: `${apiUrl}/user/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: payload,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
          showConfirmButton: false,
        });

        context.dispatch("GetAllUserManagement");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        return false;
      } finally {
        context.commit("SET_IS_LOADING_USER_MANAGEMENT", false);
      }
    },
    async DeleteUserManagement(context, id) {
      context.commit("SET_IS_LOADING_USER_MANAGEMENT", true);
      try {
        const result = await axios({
          url: `${apiUrl}/user/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("GetAllUserManagement");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        return false;
      } finally {
        context.commit("SET_IS_LOADING_USER_MANAGEMENT", false);
      }
    },
  },
};

export default userManagement;
