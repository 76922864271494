import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const form = {
  code: "",
  remarks: "",
  details: [],
};

const outboundManifest = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: "",
    list_smu: [],
    form: {
      ...form,
    },
    isUpdate: false,
  },
  mutations: {
    SET_OPTIONS_TABLE_OUTBOUND_MANIFEST(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_OUTBOUND_MANIFEST(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_OUTBOUND_MANIFEST(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_OUTBOUND_MANIFEST(state, payload) {
      state.report = payload;
    },
    SET_LIST_SMU_OUTBOUND_MANIFEST(state, payload) {
      state.list_smu = payload;
    },
    PUSH_LIST_SMU_OUTBOUND_MANIFEST(state, payload) {
      state.list_smu.push(payload);
    },
    SET_FORM_OUTBOUND_MANIFEST(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_OUTBOUND_MANIFEST(state) {
      state.form = { ...form };
    },
    SET_IS_UPDATE_OUTBOUND_MANIFEST(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async FetchAllManifest(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_OUTBOUND_MANIFEST", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
    async FetchAllManifestById(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_OUTBOUND_MANIFEST", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
    async FetchBeforeFormOutboundManifest(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const smu = await axios({
          url: `${apiUrl}/outbound/smu/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_SMU_OUTBOUND_MANIFEST", smu.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
    async FetchExportManifest(context, range) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest/export-manifest`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: range,
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        return result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
    async CreateOutboundManifest(context) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchAllManifest");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
    async UpdateStatusOutboundManifest(context, payload) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest/status/${payload.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            status: payload.status,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.dispatch("FetchAllManifest");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
    async SetFormUpdateOutboundManifest(context, id) {
      context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", true);
      try {
        const result = await axios({
          url: `${apiUrl}/outbound/manifest/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        let details = [];
        for (const iterator of result.data.data.details) {
          details.push(iterator.do_smu);

          context.commit("PUSH_LIST_SMU_OUTBOUND_MANIFEST", iterator.do_smu);
        }

        context.state.form = {
          code: result.data.data.code,
          remarks: result.data.data.remarks,
          details: details,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_OUTBOUND_MANIFEST", false);
      }
    },
  },
};

export default outboundManifest;
