var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('ContentHeader',{attrs:{"header":"Shipment Out / Bagging","title":"Scan Receipt to Bag"}}),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card pt-5"},[_c('div',{staticClass:"card-body"},[_c('v-form',{ref:"initialReport",staticClass:"row mb-5 align-items-end",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('label',[_vm._v("Bag Code")]),_c('v-combobox',{attrs:{"placeholder":"Do Code","items":_vm.list_bag,"item-text":"code","item-value":"code","outlined":"","dense":"","hide-details":"true","rules":[
                      (value) => {
                        return _vm.genericRequiredRule(value, 'Bag Code');
                      },
                    ]},on:{"change":_vm.handleChange},model:{value:(_vm.form.code_bag),callback:function ($$v) {_vm.$set(_vm.form, "code_bag", $$v)},expression:"form.code_bag"}})],1),_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('label',[_vm._v("Receipt Code")]),_c('v-text-field',{ref:"input_receipt_code",staticClass:"barcode-input",attrs:{"placeholder":"Receipt Code","outlined":"","dense":"","hide-details":"true","rules":[
                      (value) => {
                        return _vm.genericRequiredRule(value, 'Receipt Code');
                      },
                    ]},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('v-btn',{staticClass:"btn bg-navy mr-3",attrs:{"type":"submit","loading":_vm.isLoading}},[_vm._v("Scan")]),_c('v-btn',{staticClass:"btn bg-navy",on:{"click":function($event){return _vm.setStatusBagPosted()}}},[_vm._v(" Post Bag ")])],1)]),_c('div',{staticClass:"card shadow-none border rounded-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[(_vm.select_table.length > 0)?_c('v-btn',{staticClass:"btn bg-navy ml-md-3 mb-3 mb-md-0",on:{"click":function($event){return _vm.handleDeleteMany(true)}}},[_vm._v(" Delete Selected ")]):_vm._e()],1),_c('v-text-field',{staticClass:"input-search",attrs:{"label":"Cari","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list_bag_detail,"search":_vm.search,"loading":_vm.isLoading,"show-select":""},scopedSlots:_vm._u([{key:`item.date`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.receipt.receipt_date && _vm.moment(item.receipt.receipt_date).format( "DD MMMM YYYY | hh:mm" ))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{staticClass:"bg-danger",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.select_table),callback:function ($$v) {_vm.select_table=$$v},expression:"select_table"}})],1)])],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }