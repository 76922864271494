import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const requestPickup = {
  state: {
    isLoading: false,
    form: {
      code: "",
      remarks: "",
      details: [],
    },
    list_receipt_entry: [],
  },
  mutations: {
    SET_IS_LOADING_REQUEST_PICKUP(state, payload) {
      state.isLoading = payload;
    },
    SET_FORM_REQUEST_PICKUP(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_LIST_RECEIPT_ENTRY(state, payload) {
      state.list_receipt_entry = payload;
    },
  },
  actions: {
    async FetchBeforeFormRequestPickUp(context) {
      context.commit("SET_IS_LOADING_REQUEST_PICKUP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/list/ENTRY`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_RECEIPT_ENTRY", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_REQUEST_PICKUP", false);
      }
    },
    async CreateRequestPickup(context) {
      context.commit("SET_IS_LOADING_REQUEST_PICKUP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/request-pickup`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          title: "Success",
          text: result.data.message,
          icon: "success",
          confirmButtonText: "OK",
        });

        context.dispatch("FetchReceipt");
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        context.commit("SET_IS_LOADING_REQUEST_PICKUP", false);
      }
    },
  },
};

export default requestPickup;
