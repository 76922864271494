<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment In" title="In Hub Destination" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <HandleTransit />
                <v-form
                  class="row mb-5 align-items-center"
                  ref="initialReport"
                  @submit.prevent="handleSubmit"
                >
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Manifest Code</label>
                    <v-autocomplete
                      placeholder="Manifest Code"
                      item-text="code"
                      item-value="id"
                      :items="list_inbound"
                      v-model="form.id"
                      @change="handleChange"
                      outlined
                      dense
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Bag Code</label>
                    <v-text-field
                      placeholder="Bag Code"
                      outlined
                      dense
                      v-model="form.bag_code"
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Bag Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-btn class="btn bg-navy" type="submit"> Scan </v-btn>
                  </div>
                </v-form>

                <div class="card shadow-none border rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-end">
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="search"
                      />
                    </div>

                    <v-data-table
                      :headers="headers"
                      :items="reports"
                      :loading="isLoading"
                      :search="search"
                    >
                      <template v-slot:[`item.inhuborigin_date`]="{ item }">
                        {{
                          item.inhuborigin_is
                            ? moment(item.inhuborigin_date).format(
                                "DD MMMM YYYY | hh:mm"
                              )
                            : "-"
                        }}
                      </template>
                      <template v-slot:[`item.inhuborigin_is`]="{ item }">
                        {{ item.inhuborigin_is ? "WAREHOUSE ORIGIN" : "No" }}
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import moment from "moment";

export default {
  name: "ShipmentInHubDestinationPage",
  mixins: [ValidationRules],
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    HandleTransit: () => import("./handle-transit.vue"),
  },
  data() {
    return {
      moment,
      headers: [
        { text: "No", value: "no" },
        { text: "Manifest Code", value: "code_manifest" },
        { text: "Bag Code", value: "code" },
        { text: "Date", value: "inhuborigin_date" },
        { text: "Is Received", value: "inhuborigin_is" },
      ],
      search: "",
      form: {
        id: "",
        bag_code: "",
      },
    };
  },
  watch: {
    form(val) {
      if (val.id == "") {
        this.$store.commit("SET_REPORTS_IN_HUB_DESTINATION", []);
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.inHubDestination.isLoading;
    },
    list_inbound() {
      return this.$store.state.inHubDestination.list_inbound;
    },
    reports() {
      return this.$store.state.inHubDestination.reports;
    },
  },
  methods: {
    handleChange(id) {
      this.$store.dispatch("FetchInboundDetailInHubDestination", id);
    },
    handleSubmit() {
      this.$refs.initialReport.validate();

      if (this.$refs.initialReport.validate()) {
        this.$store
          .dispatch("UpdateStatusInboundInHubDestination", this.form)
          .then(() => {
            this.form.bag_code = "";

            const isExist = this.list_inbound.find(
              (item) => item.id == this.form.id
            );
            if (!isExist) {
              this.form.id = "";
              this.$$store.commit("SET_REPORTS_IN_HUB_DESTINATION", []);
            }
          });
      }
    },
  },
  mounted() {
    this.$store.commit("SET_REPORTS_IN_HUB_DESTINATION", []);

    this.$store.dispatch("FetchBeforeFormInHubDestination");
  },
};
</script>
