var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('ContentHeader',{attrs:{"header":"Shipment In","title":"Delivered"}}),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card pt-5"},[_c('div',{staticClass:"card-body"},[_c('v-form',{ref:"initialReport",staticClass:"row mb-5 align-items-center",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('label',[_vm._v("Sortir Code")]),_c('v-autocomplete',{attrs:{"placeholder":"Sortir Code","item-text":"code","item-value":"id","items":_vm.list_sortir,"outlined":"","dense":"","rules":[
                      (value) => {
                        return _vm.genericRequiredRule(value, 'Sortir Code');
                      },
                    ]},on:{"change":_vm.handleChange},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('label',[_vm._v("Receipt Code")]),_c('v-text-field',{attrs:{"placeholder":"Receipt Code","outlined":"","dense":"","rules":[
                      (value) => {
                        return _vm.genericRequiredRule(value, 'Receipt Code');
                      },
                    ]},model:{value:(_vm.receipt_code),callback:function ($$v) {_vm.receipt_code=$$v},expression:"receipt_code"}})],1),_c('div',{staticClass:"col-md-5 col-lg-3 mb-3 mb-sm-0"},[_c('v-btn',{staticClass:"btn bg-navy mr-3",attrs:{"type":"submit"}},[_vm._v(" Scan ")]),_c('v-btn',{staticClass:"btn bg-navy",on:{"click":_vm.handleFailed}},[_vm._v(" Set To Failed ")])],1)]),_c('div',{staticClass:"card shadow-none border rounded-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-text-field',{staticClass:"input-search",attrs:{"label":"Cari","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"loading":_vm.isLoading,"search":_vm.search},scopedSlots:_vm._u([{key:`item.is_received`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.is_received ? "Received" : "Not Received")+" ")]}}],null,true)})],1)])],1)])])])])]),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.modalForm),callback:function ($$v) {_vm.modalForm=$$v},expression:"modalForm"}},[_c('Form',{on:{"handleModalForm":_vm.handleModalForm}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.modalFormFailed),callback:function ($$v) {_vm.modalFormFailed=$$v},expression:"modalFormFailed"}},[_c('FormFailed',{on:{"handleModalFormFailed":_vm.handleModalFormFailed}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }