<template>
  <v-autocomplete
    dense
    outlined
    :placeholder="placeholder"
    :value="value"
    :items="options"
    :loading="isLoading"
    item-text="address"
    item-value="id"
    @change="onChange"
    :search-input.sync="destinationSearch"
    :rules="[
      (value) => {
        return genericRequiredRule(value, 'City Destination');
      },
    ]"
  />
</template>

<script>
import { ValidationRules } from '@/mixins/validation-rules';
import catchUnauthorized from '@/utils/catch-unauthorized';
import axios from 'axios';
import _ from 'lodash';

const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "CityDestinationSelect",
  mixins: [ValidationRules],
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Select from city",
    },
    value: {
      required: true,
    },
    fromCity: {
      required: true,
    }
  },
  data() {
    return {
      destinationSearch: '',
      isLoading: false,
      options: [],
    }
  },
  methods: {
    async fetchListToCity() {
      this.isLoading = true;
      try {
        const result = await axios({
          url: `${apiUrl}/check-price/to-city/${this.fromCity}`,
          method: "GET",
          params: { keyword: this.destinationSearch },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.options = result.data.data;
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        this.isLoading = false;
      }
    },
    onChange(value) {
      this.$emit('change', value)
      this.$emit('input', value)
    },
    onDestinationKeywordChanged: _.throttle(function(){
      this.fetchListToCity()
    }, 1000),
  },
  computed: {
    token(){
      return this.$store.state.app.token;
    },
  },
  watch:{
    destinationSearch(value,) {
      if(value != null && !value.match(/.*,.*,.*/)) {
        this.onDestinationKeywordChanged();
      }
    },
  },
}
</script>

<style>

</style>
