<template>
  <div id="app">
    <div class="wrapper" v-if="token">
      <AppTopbar />
      <AppSidebar />

      <v-app class="text-sm">
        <router-view />
      </v-app>

      <AppFooter />
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
    </div>

    <v-app class="login-box m-0" v-else>
      <router-view />
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    AppTopbar: () => import("@/components/app/topbar/index.vue"),
    AppSidebar: () => import("@/components/app/sidebar/index.vue"),
    AppFooter: () => import("@/components/app/footer/index.vue"),
  },
  computed: {
    token() {
      return this.$store.state.app.token;
    },
  },
  created() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token) {
      this.$store.commit("SET_TOKEN_APP", token);
      this.$store.commit("SET_USER_APP", JSON.parse(user));

      this.$store.dispatch("GetInboundEntry");
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";

@media screen and (max-width: 768px) {
  .login-box {
    width: 100% !important;
  }
}
</style>
