import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const ceisa = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    report: {
      id: "",
      type_id: "",
      no_id: "",
      name: "",
      address: "",
      no_izin: "",
      date_izin: "",
      code_country: "",
      code_city: "",
    },
  },
  mutations: {
    SET_OPTIONS_TABLE_CEISA(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_CEISA(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORT_CEISA(state, payload) {
      state.report = Object.assign({}, payload);
    },
    SET_FORM_REPORT_CEISA(state, payload) {
      state.report[payload.key] = payload.value;
    },
  },
  actions: {
    async GetCeisa(context) {
      context.commit("SET_IS_LOADING_CEISA", true);
      try {
        const result = await axios({
          url: `${apiUrl}/ceisa`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_CEISA", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CEISA", false);
      }
    },
    async UpdateCeisa(context) {
      context.commit("SET_IS_LOADING_CEISA", true);
      try {
        const result = await axios({
          url: `${apiUrl}/ceisa`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.report,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetCeisa");
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CEISA", false);
      }
    },
  },
};

export default ceisa;
