import Vue from "vue";
import VueRouter from "vue-router";

import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

import Login from "@/views/auth/login.vue";

import Dashboard from "@/views/dashboard/index.vue";
import CheckPrice from "@/views/check-price/index.vue";
import CheckResi from "@/views/check-resi/index.vue";

//   =====================================================================
//   SHIPMENT OUT
//   =====================================================================

import ShipmentOutReceiptList from "@/views/shipment-out/receipt-list/index.vue";
import ShipmentOutInHubOrigin from "@/views/shipment-out/in-hub-origin/index.vue";
import ShipmentOutInHubOriginReceiptAtWarehouse from "@/views/shipment-out/in-hub-origin/receive-at-warehouse.vue";
import ShipmentOutBagging from "@/views/shipment-out/bagging/index.vue";
import ShipmentOutBaggingScanReceiptInBag from "@/views/shipment-out/bagging/scan-receipt-in-bag.vue";
import ShipmentOutOutboundCentral from "@/views/shipment-out/outbound/central/index.vue";
import ShipmentOutOutboundSMU from "@/views/shipment-out/outbound/smu/index.vue";
import ShipmentOutOutboundManifest from "@/views/shipment-out/outbound/manifest/index.vue";

//   =====================================================================
//   SHIPMENT IN
//   =====================================================================

import ShipmentInInbound from "@/views/shipment-in/inbound/index.vue";
import ShipmentInInHubDestination from "@/views/shipment-in//in-hub-destination/index.vue";
import ShipmentInSortir from "@/views/shipment-in/sortir/index.vue";
import ShipmentInDelivery from "@/views/shipment-in/delivery/index.vue";
import ShipmentInDelivered from "@/views/shipment-in/delivered/index.vue";

//   =====================================================================
//   SETUP
//   =====================================================================

import SetupBusinessUnit from "@/views/setup/business-unit/index.vue";
import SetupCity from "@/views/setup/city/index.vue";
import SetupCeisa from "@/views/setup/ceisa/index.vue";
import SetupRateCity from "@/views/setup/rate-city/index.vue";
import SetupThreeLetterCode from "@/views/setup/three-letter-code/index.vue";
import SetupCommodity from "@/views/setup/commodity/index.vue";
import SetupCustomer from "@/views/setup/customer/index.vue";
import SetupStatus from "@/views/setup/status/index.vue";
import SetupTaxSpecialShipping from "@/views/setup/tax-special-shipping/index.vue";

//   =====================================================================
//   UTILITY
//   =====================================================================

import UtilityNumberingPrefix from "@/views/utility/numbering-prefix/index.vue";
import UtilityUserManagement from "@/views/utility/user-management/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/check/check-price",
    name: "Check Price",
    component: CheckPrice,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/check/check-resi",
    name: "Check Resi",
    component: CheckResi,
    meta: {
      middleware: [auth],
    },
  },

  //   =====================================================================
  //   SHIPMENT OUT
  //   =====================================================================

  {
    path: "/shipment-out/receipt-list",
    name: "Shipment Out Receipt List",
    component: ShipmentOutReceiptList,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/in-hub-origin",
    name: "Shipment Out In Hub Origin",
    component: ShipmentOutInHubOrigin,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/in-hub-origin/receive-at-warehouse",
    name: "Shipment Out In Hub Origin Receipt At Warehouse",
    component: ShipmentOutInHubOriginReceiptAtWarehouse,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/bagging",
    name: "Shipment Out Bagging",
    component: ShipmentOutBagging,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/bagging/scan-receipt-in-bag",
    name: "Shipment Out Bagging Scan Receipt In Bag",
    component: ShipmentOutBaggingScanReceiptInBag,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/outbound-central",
    name: "Shipment Out Outbound Central",
    component: ShipmentOutOutboundCentral,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/outbound-smu",
    name: "Shipment Out Outbound SMU",
    component: ShipmentOutOutboundSMU,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-out/outbound-manifest",
    name: "Shipment Out Outbound Manifest",
    component: ShipmentOutOutboundManifest,
    meta: {
      middleware: [auth],
    },
  },

  //   =====================================================================
  //   SHIPMENT IN
  //   =====================================================================

  {
    path: "/shipment-in/inbound",
    name: "Shipment In Inbound",
    component: ShipmentInInbound,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-in/in-hub-destination",
    name: "Shipment In In Hub Destination",
    component: ShipmentInInHubDestination,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-in/sortir",
    name: "Shipment In Sortir",
    component: ShipmentInSortir,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-in/delivery",
    name: "Shipment In Delivery",
    component: ShipmentInDelivery,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/shipment-in/delivered",
    name: "Shipment In Delivered",
    component: ShipmentInDelivered,
    meta: {
      middleware: [auth],
    },
  },

  //   =====================================================================
  //   SETUP
  //   =====================================================================

  {
    path: "/setup/business-unit",
    name: "Setup Business Unit",
    component: SetupBusinessUnit,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/city",
    name: "Setup City",
    component: SetupCity,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/ceisa",
    name: "Setup Ceisa",
    component: SetupCeisa,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/rate-city",
    name: "Setup Rate City",
    component: SetupRateCity,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/three-letter-code",
    name: "Setup Three Letter Code",
    component: SetupThreeLetterCode,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/commodity",
    name: "Setup Commodity",
    component: SetupCommodity,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/customer",
    name: "Setup Customer",
    component: SetupCustomer,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/status",
    name: "Setup Status",
    component: SetupStatus,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/setup/tax-special-shipping",
    name: "Setup Tax Special Shipping",
    component: SetupTaxSpecialShipping,
    meta: {
      middleware: [auth],
    },
  },

  //   =====================================================================
  //   UTILITY
  //   =====================================================================

  {
    path: "/utility/numbering-prefix",
    name: "Utility Numbering Prefix",
    component: UtilityNumberingPrefix,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/utility/user-management",
    name: "Utility User Management",
    component: UtilityUserManagement,
    meta: {
      middleware: [auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.path === "/") return next("/dashboard");
  return next();
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
