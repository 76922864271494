<template>
  <div class="">
    <div class="card card-outline card-navy">
      <div class="card-header text-center py-5">
        <img src="@/assets/logo.png" width="90" alt="" />
      </div>
      <div class="card-body text-left">
        <p class="login-box-msg">Sign in to start your session</p>

        <v-form ref="initialReport" @submit.prevent="handleSubmit">
          <div class="">
            <v-text-field
              label="username/email address"
              v-model="uid"
              dense
              outlined
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'username/email address');
                },
              ]"
            />
          </div>
          <div class="">
            <v-text-field
              label="password"
              v-model="password"
              dense
              outlined
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'password');
                },
              ]"
            />
          </div>
          <div class="row">
            <div class="col-12">
              <v-btn
                type="submit"
                class="btn bg-navy btn-block"
                :loading="isLoading"
              >
                Sign In
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";

export default {
  name: "LoginPage",
  mixins: [ValidationRules],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.app.isLoading;
    },
    uid: {
      get() {
        return this.$store.state.app.login.uid;
      },
      set(value) {
        this.$store.commit("SET_LOGIN_APP", {
          key: "uid",
          value,
        });
      },
    },
    password: {
      get() {
        return this.$store.state.app.login.password;
      },
      set(value) {
        this.$store.commit("SET_LOGIN_APP", {
          key: "password",
          value,
        });
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("Login").then((response) => {
          if (response) {
            this.$refs.initialReport.reset();
          }
        });
      }
    },
  },
  mounted() {
    document.body.classList.add("login-page");
  },
  beforeDestroy() {
    document.body.classList.remove("login-page");
  },
};
</script>
