<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2 mt-3">
          <div class="col-sm-6">
            <h1>Check Resi</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-3">
            <v-card
              class="shadow-none border py-4 px-3 h-100"
              :loading="isLoading"
            >
              <div class="card-body">
                <v-form ref="initialReport" @submit.prevent="handleSubmit">
                  <h4 class="fw-bold">Check No Resi</h4>

                  <div class="row align-items-center mt-3">
                    <div class="col-12 col-lg-4">
                      <label class="form-label small">No Resi</label>
                      <v-text-field
                        outlined
                        dense
                        placeholder="No Resi"
                        v-model="noResi"
                        :rules="[
                          (value) => {
                            return genericRequiredRule(value, 'No Resi');
                          },
                        ]"
                      />
                    </div>
                    <div class="col-12 col-lg-2 mt-3 mt-lg-0">
                      <v-btn
                        class="btn bg-navy px-10"
                        type="submit"
                        :loading="isLoading"
                      >
                        Cek Resi
                      </v-btn>
                    </div>
                  </div>
                </v-form>
              </div>
            </v-card>
          </div>
        </div>

        <div class="row" v-if="result">
          <div class="col">
            <v-card class="shadow-none border mt-5 mb-3">
              <div class="card-body">
                <h4 class="mb-5 fw-medium">Result from {{ result.code }}</h4>

                <table class="mb-10">
                  <tr
                    v-for="(item, i) in [
                      {
                        key: 'Pengirim',
                        value: result.sender_name,
                        value2: result.sender_address,
                      },
                      {
                        key: 'Penerima',
                        value: result.receiver_name,
                        value2: result.receiver_address,
                      },
                    ]"
                    :key="i"
                    style="vertical-align: top"
                  >
                    <th style="min-width: 150px">{{ item.key }}</th>
                    <th style="min-width: 20px">:</th>
                    <td>
                      {{ item.value }} <br />
                      {{ item.value2 }}
                    </td>
                  </tr>
                </table>

                <table class="table">
                  <tr>
                    <th>Date</th>
                    <th>Remark</th>
                  </tr>
                  <tr v-for="(item, i) in result.receipt_status" :key="i">
                    <td>
                      {{
                        moment(item.created_at).format("DD MMMM YYYY - HH:mm")
                      }}
                    </td>
                    <td>{{ item.remark_external }}</td>
                  </tr>
                </table>

                <br />
                <p>Received Image</p>
                <a
                  v-if="result.received_img"
                  :href="staticUrlImage(result.received_img)"
                  target="_blank"
                >
                  <img
                    :src="staticUrlImage(result.received_img)"
                    alt=""
                    width="200"
                  />
                </a>
                <p v-else class="text-muted">Not Upload Received Image</p>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import moment from "moment";
import { staticUrlImage } from "@/utils/static-url";

export default {
  name: "CheckResi",
  mixins: [ValidationRules],
  data() {
    return {
      moment,
      noResi: "",
      staticUrlImage,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.checkResi.isLoading;
    },
    result() {
      return this.$store.state.checkResi.result;
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.initialReport.validate();
      if (this.$refs.initialReport.validate()) {
        // console.log("this.noResi", this.noResi);
        this.$store.dispatch("CheckResi", this.noResi);
      }
    },
  },
  beforeDestroy() {
    this.$refs.initialReport.reset();
    this.$store.commit("SET_RESULT_CHECK_RESI", "");
  },
};
</script>
