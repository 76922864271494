import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
import Swal from "sweetalert2";
const apiUrl = process.env.VUE_APP_API_URL;

const form = {
  id_province: "",
  name: "",
};

const city = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    list_province: [],
    form: { ...form },
    isUpdate: false,
  },
  mutations: {
    SET_OPTIONS_TABLE_CITY(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_CITY(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_CITY(state, payload) {
      state.reports = payload;
    },
    SET_LIST_PROVINCE_CITY(state, payload) {
      state.list_province = payload;
    },
    SET_FORM_CITY(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_CITY(state) {
      state.form = { ...form };
    },
    SET_IS_UPDATE_CITY(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllCity(context) {
      context.commit("SET_IS_LOADING_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/city`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        // add no to data
        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_CITY", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CITY", false);
      }
    },
    async FetchBeforeFormCity(context) {
      context.commit("SET_IS_LOADING_CITY", true);
      try {
        const province = await axios({
          url: `${apiUrl}/province`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_PROVINCE_CITY", province.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CITY", false);
      }
    },
    async CreateCity(context) {
      context.commit("SET_IS_LOADING_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/city`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetAllCity");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CITY", false);
      }
    },
    async SetFormUpdateCity(context, id) {
      context.commit("SET_IS_LOADING_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/city/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.state.form = {
          id_province: result.data.data.id_province,
          name: result.data.data.name,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_CITY", false);
      }
    },
    async UpdateCity(context, id) {
      context.commit("SET_IS_LOADING_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/city/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetAllCity");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CITY", false);
      }
    },
    async DeleteCity(context, id) {
      context.commit("SET_IS_LOADING_CITY", true);
      try {
        const result = await axios({
          url: `${apiUrl}/city/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });
        context.dispatch("GetAllCity");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_IS_LOADING_CITY", false);
      }
    },
  },
};

export default city;
