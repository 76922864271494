import axios from "axios";
import catchUnauthorized from "@/utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const inbound = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    list_bag: [],
    list_inbound_entry: [],
    form: {
      code: "",
      remarks: "",
      details: [],
    },
    isUpdate: "",
    list_customer: [],
    list_customer_receipt: [],
  },
  mutations: {
    SET_OPTIONS_TABLE_INBOUND(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_INBOUND(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_INBOUND(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_INBOUND(state, payload) {
      state.report = payload;
    },
    SET_LIST_BAG_INBOUND(state, payload) {
      state.list_bag = payload;
    },
    SET_LIST_INBOUND_ENTRY(state, payload) {
      state.list_inbound_entry = payload;
    },
    SET_FORM_INBOUND(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_INBOUND(state, payload) {
      state.isUpdate = payload;
    },
    SET_LIST_CUSTOMER_INBOUND(state, payload) {
      state.list_customer = payload;
    },
    SET_LIST_CUSTOMER_RECEIPT_INBOUND(state, payload) {
      state.list_customer_receipt = payload;
    },
  },
  actions: {
    async GetAllInbound(context) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound?start_date=${context.rootState.app.filter_range.start_date}&end_date=${context.rootState.app.filter_range.end_date}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, i) => {
          item.no = i + 1;
        });

        context.commit("SET_REPORTS_INBOUND", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
    async GetInboundEntry(context) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound/list-entry`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_INBOUND_ENTRY", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
    async GetInboundById(context, id) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_INBOUND", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
    async UpdateStatusInbound(context, code_bag) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/inbound/update-status/${code_bag}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          title: "Success",
          text: result.data.message,
          icon: "success",
          confirmButtonText: "OK",
        });

        context.dispatch("GetAllInbound");
        context.dispatch("GetInboundEntry");

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
    async GetListCustomerInbound(context) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/customer/list`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_CUSTOMER_INBOUND", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
    async GetListCustomerReceiptInbound(context, id_customer) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/unique/${id_customer}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_LIST_CUSTOMER_RECEIPT_INBOUND", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
    async UpdateStatusCustomerReceiptInbound(context, data) {
      context.commit("SET_IS_LOADING_INBOUND", true);
      try {
        const result = await axios({
          url: `${apiUrl}/receipt/unique`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
            "Content-Type": "multipart/form-data",
          },
          data: {
            data_receipt: data.data_receipt.map((item) => {
              return {
                id: item.id,
              };
            }),
            received_img: data.received_img,
          },
        });

        Swal.fire({
          title: "Success",
          text: result.data.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        context.commit("SET_IS_LOADING_INBOUND", false);
      }
    },
  },
};

export default inbound;
