<template>
  <div class="content-wrapper">
    <ContentHeader header="Setup" title="Setup Tax Special Shipping" />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <v-card class="py-3 shadow-none border" :loading="isLoading">
              <div class="card-body card-dashboard">
                <v-form ref="initialReport" @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Amount Tax Retail</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            outlined
                            dense
                            hide-details="auto"
                            v-model="amount_tax_retail"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(
                                  value,
                                  'Amount Tax Retail'
                                );
                              },
                            ]"
                            type="number"
                            :messages="format3Digit(amount_tax_retail)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-md-4 col-lg-3 col-xl-2">
                          <span>Amount Tax BI</span>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xl-10">
                          <v-text-field
                            outlined
                            dense
                            hide-details="auto"
                            v-model="amount_tax_bi"
                            :rules="[
                              (value) => {
                                return genericRequiredRule(
                                  value,
                                  'Amount Tax BI'
                                );
                              },
                            ]"
                            type="number"
                            :messages="format3Digit(amount_tax_bi)"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <button
                        type="submit"
                        data-save="modal"
                        class="btn bg-navy mr-1 py-2 px-5"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        class="btn btn-outline-secondary mr-1 py-2 px-5"
                        @click="handleCancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </v-form>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import format3Digit from "@/utils/format-3digit";

export default {
  name: "SetupTaxSpecialShipping",
  mixins: [ValidationRules],
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
  },
  data() {
    return {
      format3Digit,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.specialShipping.isLoading;
    },
    amount_tax_bi: {
      get() {
        return this.$store.state.specialShipping.form.amount_tax_bi;
      },
      set(value) {
        this.$store.commit("SET_FORM_SPECIAL_SHIPPING", {
          key: "amount_tax_bi",
          value,
        });
      },
    },
    amount_tax_retail: {
      get() {
        return this.$store.state.specialShipping.form.amount_tax_retail;
      },
      set(value) {
        this.$store.commit("SET_FORM_SPECIAL_SHIPPING", {
          key: "amount_tax_retail",
          value,
        });
      },
    },
  },
  methods: {
    handleCancel() {
      this.$store.dispatch("GetSpecialShipping");
    },
    handleSubmit() {
      this.$refs.initialReport.validate();
      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("UpdateSpecialShipping");
      }
    },
  },
  mounted() {
    this.$store.dispatch("GetSpecialShipping");
  },
};
</script>
