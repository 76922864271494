var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('ContentHeader',{attrs:{"header":"Setup","title":"Setup Tax Special Shipping"}}),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-card',{staticClass:"py-3 shadow-none border",attrs:{"loading":_vm.isLoading}},[_c('div',{staticClass:"card-body card-dashboard"},[_c('v-form',{ref:"initialReport",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Amount Tax Retail")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(
                                value,
                                'Amount Tax Retail'
                              );
                            },
                          ],"type":"number","messages":_vm.format3Digit(_vm.amount_tax_retail)},model:{value:(_vm.amount_tax_retail),callback:function ($$v) {_vm.amount_tax_retail=$$v},expression:"amount_tax_retail"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-4 col-lg-3 col-xl-2"},[_c('span',[_vm._v("Amount Tax BI")])]),_c('div',{staticClass:"col-md-8 col-lg-9 col-xl-10"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","rules":[
                            (value) => {
                              return _vm.genericRequiredRule(
                                value,
                                'Amount Tax BI'
                              );
                            },
                          ],"type":"number","messages":_vm.format3Digit(_vm.amount_tax_bi)},model:{value:(_vm.amount_tax_bi),callback:function ($$v) {_vm.amount_tax_bi=$$v},expression:"amount_tax_bi"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn bg-navy mr-1 py-2 px-5",attrs:{"type":"submit","data-save":"modal"}},[_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-outline-secondary mr-1 py-2 px-5",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")])])])])],1)])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }