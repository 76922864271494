<template>
  <div class="content-wrapper">
    <ContentHeader
      header="Shipment Out / In Hub Origin"
      title="Receive at Warehouse"
    />

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <v-form
                  class="row mb-5 align-items-center"
                  ref="initialReport"
                  @submit.prevent="handleSubmit"
                >
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Do Code</label>
                    <v-autocomplete
                      placeholder="Do Code"
                      :items="list_inHubOrigin"
                      item-text="code"
                      item-value="id"
                      @change="handleChangeDoCode"
                      outlined
                      dense
                      v-model="form.idInHubOrigin"
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Do Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <label>Receipt Code</label>
                    <v-text-field
                      placeholder="Receipt Code"
                      outlined
                      dense
                      v-model="form.idReceipt"
                      class="barcode-input"
                      :rules="[
                        (value) => {
                          return genericRequiredRule(value, 'Receipt Code');
                        },
                      ]"
                    />
                  </div>
                  <div class="col-md-5 col-lg-3 mb-3 mb-sm-0">
                    <v-btn
                      class="btn bg-navy"
                      type="submit"
                      :loading="isLoading"
                    >
                      Scan
                    </v-btn>
                  </div>
                </v-form>
                <div class="card shadow-none border rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-end">
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="search"
                      />
                    </div>

                    <v-data-table
                      :headers="headers"
                      :items="list_receipt_receiveAtWarehouse"
                      :search="search"
                      :loading="isLoading"
                    >
                      <template v-slot:[`item.received_date`]="{ item }">
                        {{
                          item.received_date &&
                          moment(item.received_date).format(
                            "DD MMMM YYYY | hh:mm"
                          )
                        }}
                      </template>
                      <template v-slot:[`item.remarks`]="{ item }">
                        {{ item.is_received ? "WAREHOUSE ORIGIN" : "" }}
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import moment from "moment";
import Toastify from "toastify-js";

export default {
  name: "ReceiveAtWarehousePage",
  mixins: [ValidationRules],
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
  },
  data() {
    return {
      search: "",
      moment,
      headers: [
        { text: "No", value: "no" },
        { text: "No Resi", value: "receipt.code" },
        { text: "Date Time", value: "received_date" },
        { text: "Remarks", value: "remarks" },
      ],
      form: {
        idReceipt: "",
        idInHubOrigin: "",
      },
    };
  },
  watch: {
    form(val) {
      if (val.idReceipt == "") {
        this.$store.commit("SET_LIST_RECEIPT_RECEIVE_AT_WAREHOUSE", []);
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.inHubOrigin.isLoading;
    },
    list_inHubOrigin() {
      return this.$store.state.inHubOrigin.list_inHubOrigin;
    },
    list_receipt_receiveAtWarehouse() {
      return this.$store.state.inHubOrigin.list_receipt_receiveAtWarehouse;
    },
  },
  methods: {
    handleChangeDoCode(val) {
      this.$store.dispatch("GetListReceiptInhubOiriginPosted", val);
    },
    handleSubmit() {
      this.$refs.initialReport.validate();

      if (this.$refs.initialReport.validate()) {
        this.$store
          .dispatch("UpdateStatusReceiptDoAgent", this.form)
          .then((val) => {
            if (val) {
              this.form.idReceipt = "";

              Toastify({
                text: "Success receive receipt",
                className: "success",
                gravity: "bottom",
              }).showToast();
            } else {
              Toastify({
                text: "Receipt already received",
                style: {
                  background: "#dc3545",
                },
                gravity: "bottom",
              }).showToast();
            }
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch("GetListInhubOiriginPosted");
  },
  beforeDestroy() {
    this.$store.commit("SET_LIST_RECEIPT_RECEIVE_AT_WAREHOUSE", []);
  },
};
</script>
