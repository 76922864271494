import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const numberingPrefix = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    form: {
      id: "",
      desc: "",
      format: "",
      prefix: "",
    },
  },
  mutations: {
    SET_OPTIONS_TABLE_NUMBERING_PREFIX(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_IS_LOADING_NUMBERING_PREFIX(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_NUMBERING_PREFIX(state, payload) {
      state.reports = payload;
    },
    SET_FORM_NUMBERING_PREFIX(state, payload) {
      state.form[payload.key] = payload.value;
    },
  },
  actions: {
    async GetAllNumberingPrefix(context) {
      context.commit("SET_IS_LOADING_NUMBERING_PREFIX", true);
      try {
        const result = await axios({
          url: `${apiUrl}/numbering-prefix`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORTS_NUMBERING_PREFIX", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_NUMBERING_PREFIX", false);
      }
    },
    async SetFormUpdateNumberingPrefix(context, id) {
      context.commit("SET_IS_LOADING_NUMBERING_PREFIX", true);

      try {
        const result = await axios({
          url: `${apiUrl}/numbering-prefix/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          id: result.data.data.id,
          desc: result.data.data.desc,
          format: result.data.data.format,
          prefix: result.data.data.prefix,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_IS_LOADING_NUMBERING_PREFIX", false);
      }
    },
    async UpdateNumberingPrefix(context) {
      context.commit("SET_IS_LOADING_NUMBERING_PREFIX", true);

      try {
        const result = await axios({
          url: `${apiUrl}/numbering-prefix/${context.state.form.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            desc: context.state.form.desc,
            format: context.state.form.format,
            prefix: context.state.form.prefix,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: result.data.message,
        });

        context.state.form = {
          id: "",
          desc: "",
          format: "",
          prefix: "",
        };

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.dispatch("GetAllNumberingPrefix");
        context.commit("SET_IS_LOADING_NUMBERING_PREFIX", false);
      }
    },
  },
};

export default numberingPrefix;
