<template>
  <div class="content-wrapper">
    <ContentHeader header="Shipment Out" title="Bagging" />

    <section class="content">
      <div class="container-fluid">
        <div class="d-md-flex flex-wrap mb-3 justify-content-end">
          <v-btn
            class="btn bg-navy ml-md-3 mb-3 mb-md-0"
            @click="handleModalForm(true)"
            v-if="isHub"
          >
            Add Bag
          </v-btn>
          <v-btn
            class="btn bg-navy ml-md-3 mb-3 mb-md-0"
            @click="
              () => {
                $router.push('/shipment-out/bagging/scan-receipt-in-bag');
              }
            "
            v-if="isHub"
          >
            Scan Receipt to Bag
          </v-btn>
          <HandleExportCeisa />
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card pt-5">
              <div class="card-body">
                <div class="row mb-5">
                  <div class="col-md-7 col-lg-6 mb-3 mb-sm-0">
                    <DateRangePicker />
                  </div>
                </div>
                <ul class="pl-0 nav nav-tabs">
                  <li class="nav-item" v-for="(item, i) in tab_list" :key="i">
                    <a
                      :class="`nav-link ${
                        item.title === tab_active && 'active fw-semibold'
                      }`"
                      @click="() => (tab_active = item.title)"
                    >
                      {{ item.title }}
                      <span :class="`badge ${item.color} badge-pill`">
                        {{ item.value }}
                      </span>
                    </a>
                  </li>
                </ul>

                <div class="card shadow-none border border-top-0 rounded-0">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <HandlePrintSelected />
                        <HandleStatusSelected />
                      </div>
                      <v-text-field
                        class="input-search"
                        label="Cari"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        v-model="optionsTable.search"
                      />
                    </div>
                    <v-data-table
                      :headers="headers"
                      :items="setReportsByTab()"
                      :loading="isLoading"
                      :options.sync="optionsTable"
                      :search="optionsTable.search"
                      show-select
                      v-model="select_print_barcode"
                    >
                      <template v-slot:[`item.created_at`]="{ item }">
                        {{
                          moment(item.created_at).format(
                            "DD MMMM YYYY | HH:mm:ss"
                          )
                        }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="btn btn-outline-primary py-4"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="fw-light mr-1">Action</span>
                              <i class="fa-solid fa-chevron-down small"></i>
                            </v-btn>
                          </template>
                          <v-list min-width="150">
                            <v-list-item
                              @click="handleModalDetail(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fa-regular fa-eye small mr-2"></i>
                                <span class="small">Detail</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleModalPrintBarcodeBag(true, item.id)"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-print small mr-2"></i>
                                <span class="small">Print Barcode Bag</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleEdit(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Edit</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusBagPosted(item.code)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set Posted</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="setStatusBagEntry(item.code)"
                              v-if="item.status == 'POSTED'"
                            >
                              <v-list-item-title class="text-primary">
                                <i
                                  class="fa-regular fa-pen-to-square small mr-2"
                                ></i>
                                <span class="small">Set Entry</span>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="handleDelete(item.id)"
                              v-if="item.status == 'ENTRY'"
                            >
                              <v-list-item-title class="text-primary">
                                <i class="fas fa-trash small mr-2"></i>
                                <span class="small">Delete</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modalForm" persistent max-width="800">
      <Form @handleModalForm="handleModalForm" />
    </v-dialog>
    <v-dialog v-model="modalDetail" persistent max-width="1200">
      <Detail @handleModalDetail="handleModalDetail" />
    </v-dialog>
    <v-dialog v-model="modalPrintBarcodeBag" persistent max-width="1200">
      <BarcodeBag @handleModalPrintBarcodeBag="handleModalPrintBarcodeBag" />
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "ShipmentOutBaggingPage",
  components: {
    ContentHeader: () => import("@/components/molecules/content-header.vue"),
    Form: () => import("./form.vue"),
    Detail: () => import("./detail.vue"),
    BarcodeBag: () => import("@/components/document/barcode-bag/index.vue"),
    DateRangePicker: () =>
      import("@/components/molecules/date-range-picker.vue"),
    HandleExportCeisa: () => import("./handle-export-ceisa.vue"),
    HandlePrintSelected: () => import("./handle-print-selected.vue"),
    HandleStatusSelected: () => import("./handle-status-selected.vue"),
  },
  data() {
    return {
      moment,
      tab_active: "Entry",
      tab_list: [
        { title: "Entry", value: 0, color: "badge-info" },
        { title: "Posted", value: 0, color: "badge-success" },
        { title: "All", value: 0, color: "badge-dark" },
      ],
      headers: [
        // { text: "No", value: "no" },
        { text: "Code", value: "code" },
        { text: "Date", value: "created_at" },
        { text: "Company Origin", value: "created_by.business_unit.code" },
        { text: "Company Destination", value: "business_unit_dest.code" },
        { text: "Shipping Port", value: "shipping_port.code_port" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      modalForm: false,
      modalDetail: false,
      modalPrintBarcodeBag: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.bag.isLoading;
    },
    reports() {
      return this.$store.state.bag.reports;
    },
    isHub() {
      return this.$store.state.app.user.business_unit.type === "Hub"
        ? true
        : false;
    },
    optionsTable: {
      get() {
        return this.$store.state.bag.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_BAG", value);
      },
    },
    select_print_barcode: {
      get() {
        return this.$store.state.bag.select_print_barcode;
      },
      set(value) {
        this.$store.commit("SET_SELECT_PRINT_BARCODE_BAG", value);
      },
    },
    start_date() {
      return this.$store.state.app.filter_range.start_date;
    },
    end_date() {
      return this.$store.state.app.filter_range.end_date;
    },
  },
  watch: {
    reports() {
      this.setValueTab();
    },
    async start_date() {
      await this.$store.dispatch("FetchBag");
      this.setValueTab();
    },
    async end_date() {
      await this.$store.dispatch("FetchBag");
      this.setValueTab();
    },
  },
  methods: {
    setReportsByTab() {
      if (this.tab_active === "All") return this.reports;

      return this.reports.filter(
        (x) => x.status.toLowerCase() === this.tab_active.toLowerCase()
      );
    },
    handleModalForm(val) {
      if (val) this.$store.dispatch("FetchBeforeFormBag");
      this.modalForm = val;
    },
    handleModalDetail(val, id) {
      if (val) this.$store.dispatch("FetchBagById", id);
      this.modalDetail = val;
    },
    handleModalPrintBarcodeBag(val, id) {
      if (val) this.$store.dispatch("FetchDocsBarcodeBagById", id);
      this.modalPrintBarcodeBag = val;
    },
    handleEdit(id) {
      this.$store.dispatch("SetFormUpdateBag", id);
      this.$store.commit("SET_IS_UPDATE_BAG", id);
      this.handleModalForm(true);
    },
    handleDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DeleteBag", id);
        }
      });
    },
    setStatusBagPosted(code) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will set this bag as posted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, post it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("UpdateBagStatus", {
            code: code,
            status: "POSTED",
          });
        }
      });
    },
    setStatusBagEntry(code) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will set this bag as entry!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, entry",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("UpdateBagStatus", {
            code: code,
            status: "ENTRY",
          });
        }
      });
    },
    setValueTab() {
      this.tab_list.forEach((item) => {
        item.value = this.reports.filter(
          // set lower case to make it case insensitive
          (x) => x.status.toLowerCase() === item.title.toLowerCase()
        ).length;
      });

      this.tab_list[2].value = this.reports.length;
    },
  },
  async mounted() {
    await this.$store.dispatch("FetchBag");
    this.setValueTab();
  },
};
</script>
